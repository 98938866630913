import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import HeaderDash from "../dashboard/components/HeaderDash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(ID, Date, Amount, Invoice) {
  return { ID, Date, Amount, Invoice };
}

const rows = [
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
  createData(124, "Jan 7, 2023", "$49", "/images/dashboard/profile/pdf.svg"),
];

function SubscriptionStud({ setActiveTab }) {
  const [enabled, setEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    const data = { subscription: "school" };
    navigate("/subscriptions", { state: data });
  };
  return (
    <div className="p-4 min-w-[320px]">
      <HeaderDash />
      <div className="self-center p-7 mt-8 max-w-full md:w-[80%]  bg-white rounded-3xl shadow-2xl mx-auto max-md:mr-4">
        <div className="flex gap-5 justify-between w-full max-md:flex-col max-md:w-full">
          <div className="flex md:w-[50%] gap-14 max-sm:flex-col ">
            <div className="flex flex-col  text-base font-medium text-black">
              <div className="max-md:mr-2.5 whitespace-nowrap">
                Subscription Plan
              </div>
              <div className="text-sm text-neutral-400 whitespace-nowrap">
                Individual: $49/annum
              </div>
              <div className={`${subscriptionPlan ? "hidden" : ""}`}>
                <div className="self-start mt-9 whitespace-nowrap max-md:mt-3">
                  Auto Renewal
                </div>
                <div className="self-start text-sm text-neutral-400 whitespace-nowrap">
                  Lorem Ipsum Dolar
                </div>
              </div>
              <div
                className={`self-start text-sm mt-5 font-normal max-md:w-full ${
                  subscriptionPlan ? "" : "hidden"
                }`}
              >
                Sorry to see you go away, but you can always start your
                subscription anytime. Click on the button to get started.
              </div>
            </div>
            <div
              className={`flex flex-col gap-5 justify-between w-full max-md:max-w-full ${
                subscriptionPlan ? "hidden" : ""
              }`}
            >
              <div className="flex flex-col self-start">
                <div className="text-base font-medium text-black whitespace-nowrap">
                  Plan Expires
                </div>
                <div className="self-start text-sm text-neutral-400 whitespace-nowrap">
                  July 7, 2023
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className={`relative w-12 h-6 transition duration-200 ease-linear rounded-full ${
                    enabled ? "bg-blue-500" : "bg-gray-300"
                  }`}
                  onClick={() => setEnabled(!enabled)}
                >
                  <div
                    className={`absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-linear ${
                      enabled ? "translate-x-6" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-base text-center h-14 px-4 max-md:w-full max-md:mt-2 flex items-center justify-center text-blue-600 rounded-2xl border-2 border-blue-600 border-solid ">
            {subscriptionPlan ? (
              <button onClick={handleClick}>Start Subscription</button>
            ) : (
              <button onClick={() => setIsModalOpen(true)}>Cancel Plan</button>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center self-center md:w-[80%] mx-auto p-4 mt-6 max-w-full text-sm bg-white rounded-3xl shadow-2xl text-neutral-400 max-md:mr-4">
        <div className="flex flex-wrap gap-5 justify-between self-stretch text-2xl tracking-[1px] text-green-600 max-md:max-w-full">
          <div className="my-auto font-bebas pt-3 pl-3">Billing info</div>
        </div>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: 700,
            boxShadow: "none",
            borderRadius: "16px",
            maxHeight: "350px",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
        >
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">ID</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
                <StyledTableCell align="left">Invoice</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                minWidth: 500,
                whiteSpace: "nowrap",
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                },
              }}
            >
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.ID}</StyledTableCell>
                  <StyledTableCell align="left">{row.Date}</StyledTableCell>
                  <StyledTableCell align="left">{row.Amount}</StyledTableCell>
                  <StyledTableCell align="center">
                    <img src={row.Invoice} alt="Product" className="" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {isModalOpen ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none max-md:mx-3">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col rounded-none ">
                  <div className="flex flex-col pt-7 pb-16 w-full bg-white rounded-3xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-end mr-6 h-8 w-8 bg-white rounded-2xl shadow-[0px_20px_50px_rgba(0,0,0,0.12)] max-md:mr-2.5">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className=" text-blue-600"
                      >
                        &#10005;
                      </button>
                    </div>
                    <div className="flex flex-col px-10 mt-1 w-full text-center max-md:mt-10 max-md:max-w-full">
                      <div className="text-2xl text-[#FF5271] font-bebas tracking-[0.5px] max-md:max-w-full max-md:text-xl">
                        CANCEL PLAN
                      </div>
                      <div className="text-base leading-relaxed text-neutral-400 max-md:max-w-full">
                        Are you sure you want to cancel your subscription?
                      </div>
                      <button
                        onClick={() =>
                          setSubscriptionPlan(true) & setIsModalOpen(false)
                        }
                        className="md:w-[80%] mx-auto max-md:w-full px-3 py-4 mt-10 text-sm text-white whitespace-nowrap bg-[#FF1942] rounded-2xl "
                      >
                        Cancel Plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}


export default SubscriptionStud
