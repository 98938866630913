import React, { useState, useEffect } from 'react'
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';
import { createPost, updatePost } from '../../../axios/axios';
import { CallingAxios, ClearPopupMemory, TrimJsonValues } from '../commonFunction';

const CreateCelebration = () => {
    const [isOpen, setIsOpen] = useState(false);
    const popupMemory = useSelector(state => state.common.PopupMemory)
    const [loading, setLoading] = useState(false);
    // // const { user } = useAuth();
    const user = {};

    useEffect(() => {
        if (popupMemory?.enable && popupMemory?.address == "post") {
            setIsOpen(true);
            console.log("fgsnfjgnk");
            // console.log(popupMemory?.initialValue);
        }
    }, [popupMemory]);

    const hanleClose = () => {
        setIsOpen(false);
        ClearPopupMemory();
        formik.resetForm();
    }

    const formik = useFormik({
        initialValues: {
            text: "",
            images: []
        },
        onSubmit: async (values, { setSubmitting }) => {
            const data = TrimJsonValues(values);
            setLoading(true)
            if (data == '') {
                CallingAxios(
                    await createPost(user.candidateId, data)
                        .then((response) => {
                            console.log(response);
                            hanleClose();
                            toast.success("Post created successfully");
                        })
                        .catch((err) => {
                            toast.error(err.message)
                        })
                )
            } else {
                await updatePost()
                    .then((response) => {

                    })
                    .catch((err) => {
                        toast.error(err.message)
                    })
            }
            setLoading(false)
        }
    })

    const onFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            const oldImages = formik.values.images;
            const newImages = [...oldImages, file];
            formik.setValues({ ...formik.values, images: newImages });
        }
    };

    const handleDeleteFile = (idx) => {
        const oldImages = formik.values.images;
        const newImages = oldImages.filter((_, index) => index !== idx);
        formik.setFieldValue("images", newImages);
    };

    const onChange = (e) => {
        const value = e.target.value;
        if (value.length > 200) {

        } else {
            formik.setFieldValue("text", value);
        }
    }

    return (
        isOpen &&
        <>
            <div className="justify-center items-center flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative mx:w-auto my-6 xl:mx-auto xl:max-w-3xl w-full mx-5">
                    <div className="flex flex-col items-start p-4 w-full bg-white rounded-3xl max-md:p-5 max-md:max-w-full">
                        <div className="flex justify-between w-full">
                            <div className="text-xl my-auto font-medium text-black">
                                Share your thoughts
                            </div>
                            <img
                                onClick={hanleClose}
                                loading="lazy"
                                src="/images/dashboard/celebrate/cross.png"
                                className=" my-auto bg-gray-50 cursor-pointer  rounded-full aspect-square w-[35px]"
                            />
                        </div>
                        <div className="mt-5 w-full">
                            <Textarea
                                // {...formik.getFieldProps("text")}
                                placeholder="What’s on your mind Joe?"
                                value={formik.values.text}
                                onChange={onChange}
                                minRows={2}
                                maxRows={4}
                                endDecorator={
                                    <Typography
                                        level="body-xs"
                                        sx={{ ml: "auto", mr: "10px" }}
                                    >
                                        {formik.values.text.length} /200
                                    </Typography>
                                }
                                sx={{
                                    minWidth: 300,
                                    borderRadius: "15px",
                                    height: "20vh",
                                    backgroundColor: "white",
                                    boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05) ",
                                    border: "none",
                                    outline: "none",
                                }}
                            />
                        </div>
                        <div className="flex flex-wrap gap-5 justify-between mt-5 w-full overflow-x-auto max-md:max-w-full">
                            <div className="grid xl:grid-cols-7 grid-cols-4 gap-3.5">
                                {formik.values.images.map((singleImage, idx) => (
                                    <div className='relative'>
                                        <img
                                            key={idx}
                                            onClick={() => handleDeleteFile(idx)}
                                            loading="lazy"
                                            src={"/images/dashboard/celebrate/delete-post.png"}
                                            className="w-5 absolute right-0 top-0 cursor-pointer"
                                        />
                                        <img
                                            key={idx}
                                            loading="lazy"
                                            src={URL.createObjectURL(singleImage)}
                                            className="w-16 aspect-square border rounded-xl"
                                        />
                                    </div>
                                ))}
                                <div>
                                    <input
                                        type="file"
                                        id="file-input"
                                        onChange={onFileSelect}
                                        style={{ display: 'none' }} // Hide the input
                                    />
                                    <label htmlFor="file-input" className="flex justify-between w-full cursor-pointer">
                                        <img
                                            loading="lazy"
                                            src="/images/dashboard/celebrate/add-post.png"
                                            className="w-16 aspect-square"
                                        />
                                    </label>
                                </div>

                            </div>
                        </div>
                        <Button onClick={formik.handleSubmit} loading={loading} style={"w-full mt-4"} text={"Post"} />
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default CreateCelebration