import React, { useEffect, useState } from "react";
import HeaderDash from "./components/HeaderDash";
import MicrocredentialsData from "./components/MicrocredentialsData";
import { IoIosSearch } from "react-icons/io";
import CourceDescription from "./components/CourceDescription";
import CommentSection from "./components/CommentSection";
import SingleMicrocredentials from "../components/Microcredentials";
import { CallingAxios } from "../auth/components/commonFunction";
import { toast } from "react-toastify";
import { UseGetMicroCreadiential } from "../axios/axios";
import { useAuth } from "../authContext/AuthContext";

const Microcredentials = ({ setActiveTab }) => {
  const { user } = useAuth();
  const [allMicro, setAllMicro] = useState([]);
  const [selectedMicro, setSelectedMicro] = useState();

  useEffect(() => {
    CallGetMicrovredentials();
  }, []);

  const CallGetMicrovredentials = async () => {
    console.log(user, "safsdds")
    await CallingAxios(UseGetMicroCreadiential(user.candidateId)
      .then((response) => {
        console.log(response)
        setAllMicro(response.data)
      }).catch((err) => {
        toast.error("some tecnical error");
      }))
  }
  return (
    <main className="p-4 ">
      <HeaderDash setActiveTab={setActiveTab} />
      {selectedMicro ? (
        <CourceDescription goBack={() => setSelectedMicro()} data={selectedMicro} />
      ) : (
        <div className="mt-8 max-md:mr-2.5 max-lg:max-w-full">
          <div className="flex gap-5 max-lg:flex-col">
            <div className="flex flex-col w-[67%] max-md:ml-0 max-lg:w-full">
              <div className="flex flex-col mt-1.5 w-full max-md:mt-8 max-md:max-w-full">
                <div className="flex flex-wrap items-start self-start ml-6 text-2xl font-semibold leading-none text-green-600">
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-right.png"
                    className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
                  />
                  <div className="flex-auto self-start max-md:max-w-full">
                    Dream big, strive for greatness!
                  </div>
                  <img
                    loading="lazy"
                    src="/images/dashboard/quote-left.png"
                    className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
                  />
                </div>

                <div className="flex flex-col px-5 py-7 mt-3 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
                  <div className="flex flex-wrap gap-10 justify-between max-w-full">
                    <div className="text-2xl font-bebas tracking-[1px] md:my-auto text-slate-950">
                      MICROCREDENTIALS
                    </div>
                    <div className="flex gap-2  px-3 py-2 text-sm leading-relaxed text-black bg-white border-[#E8E8E8] border-[1px] rounded-xl ">
                      <IoIosSearch className="my-auto text-2xl text-gray-400 " />
                      <input
                        type="search"
                        name="search-form"
                        id="search-form"
                        className=" outline-none border-none my-auto"
                        placeholder="Search course"
                      />
                    </div>
                  </div>
                  <div
                    style={{ scrollbarWidth: "none" }}
                    className="mt-5 h-screen overflow-y-auto"
                  >
                    {allMicro.map((item, idx) => (
                      <SingleMicrocredentials key={idx} item={item} onClick={() => setSelectedMicro(item)} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[33%]  max-md:ml-0  max-lg:w-full">
              <CommentSection />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Microcredentials;


