import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

function AddEditAchievements({
  setAddAchiements,
  onSave,
  achievement,
  onDelete,
}) {
  const [inputText, setInputText] = useState("");
  const [textareaText, setTextareaText] = useState("");
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      console.log("Selected file:", file);
      // Handle file upload logic here
    }
  };

  const getWordCount = (text) => {
    if (text.trim() === "") return 0;
    return text.trim().split(/\s+/).length;
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    const wordCount = getWordCount(text);

    if (wordCount <= 12) {
      setInputText(text);
    }
  };

  const handleTextareaChange = (e) => {
    const text = e.target.value;
    const wordCount = getWordCount(text);

    if (wordCount <= 100) {
      setTextareaText(text);
    }
  };

  useEffect(() => {
    if (achievement) {
      setInputText(achievement.title || "");
      setTextareaText(achievement.description || "");
      setFileName(achievement.fileName || "");
    }
  }, [achievement]);
  const handleSave = () => {
    onSave({
      title: inputText,
      description: textareaText,
      fileName,
    });
    setAddAchiements(null); // reset alreadt existed data
    setAddAchiements(false); // Optionally close the modal
  };

  const handleDelete = () => {
    if (achievement) {
      
      onDelete(achievement.id); // Call delete function from parent
      setAddAchiements(null); // Clear edit state
    }
  };

  return (
    <div className="flex flex-col p-5 mt-6 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
      <div className="flex gap-4 justify-between items-start self-start max-w-full">
        <div
          onClick={() => setAddAchiements(false)}
          style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
          className="bg-white rounded-full p-3 max-md:p-2 cursor-pointer"
        >
          <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
        </div>
        <div className="self-start mt-2 text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
          {achievement ? "EDIT Achievements" : "Add Achievements"}
        </div>
      </div>
      <div className="self-start ml-[62px] text-sm leading-loose text-neutral-400 max-md:ml-2.5">
        {achievement
          ? "In this section, edit your achievements"
          : "In this section, add your multiple achievements"}
      </div>
      <div className="px-24 max-md:px-0">
        <div
          onClick={handleUploadClick}
          className="flex flex-col justify-center items-center self-center px-10 py-7 mt-9 max-w-full leading-loose text-center rounded-xl border border-dashed bg-zinc-100 bg-opacity-80 border-stone-300 w-full max-md:px-5"
        >
          <div className="flex flex-col items-center max-w-full w-full">
            <img
              loading="lazy"
              src="/images/dashboard/profile/upload.png"
              className="object-contain aspect-square w-[30px]"
            />
            <div className="self-stretch mt-2 text-xs bg-clip-text bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] text-indigo-950">
              <span className="text-blue-600">Browse </span>
              <span className="text-indigo-950">your image</span>
            </div>
            <div className="text-xs text-neutral-400">Supports JPEG, PNG</div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => setFileName(e.target.value)}
            accept="image/jpeg, image/png"
          />
          {fileName && (
            <span className="font-medium text-[14px]">[{fileName}]</span>
          )}
        </div>
        <div className="w-full mx-auto">
          <div className="mt-10 max-md:mt-5">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="p-3 rounded-lg"
            >
              <div className="font-semibold text-base">Title</div>
              <input
                type="text"
                className="w-full rounded-md border-none outline-none text-base "
                placeholder="In this section, add your title"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
            </div>
            <div className="text-gray-500 text-sm text-right mt-1">
              {getWordCount(inputText)}/12
            </div>
          </div>
          <div className="mt-7 max-md:mt-5">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="p-3 rounded-lg"
            >
              <div className="font-semibold text-base">Description</div>
              <textarea
                className="w-full outline-none border-none"
                placeholder=""
                value={textareaText}
                onChange={(e) => setTextareaText(e.target.value)}
                rows={4}
              />
            </div>
            <div className="text-gray-500 text-sm text-right mt-1">
              {getWordCount(textareaText)}/100
            </div>
          </div>
        </div>
        <div className="flex gap-6 self-center mt-9 max-w-full text-base text-center text-white whitespace-nowrap max-md:gap-3 w-full">
          {achievement ? (
            <button
            onClick={() => setIsModalOpen(true)}
              style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
              className="bg-white flex justify-center items-center rounded-xl w-12 h-12 p-3 max-md:p-2 cursor-pointer"
            >
              <RiDeleteBin6Line className="text-[#FF1942] text-xl max-md:w-4 max-md:h-4" />
            </button>
          ) : (
            ""
          )}

          <button
            onClick={handleSave}
            className="p-4 max-w-full w-full text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
          >
            Save
          </button>
        </div>
      </div>
      {isModalOpen ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none max-md:mx-3">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col rounded-none ">
                  <div className="flex flex-col pt-7 pb-16 w-full bg-white rounded-3xl max-md:max-w-full">
                    <div className="flex gap-2.5 justify-center items-center self-end mr-6 h-8 w-8 bg-white rounded-2xl shadow-[0px_20px_50px_rgba(0,0,0,0.12)] max-md:mr-2.5">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className=" text-blue-600"
                      >
                        &#10005;
                      </button>
                    </div>
                    <div className="flex flex-col px-10 mt-1 w-full text-center max-md:mt-10 max-md:max-w-full">
                      <div className="text-2xl uppercase text-[#FF5271] font-bebas tracking-[0.5px] max-md:max-w-full max-md:text-xl">
                      delete ACHIEVEMENT
                      </div>
                      <div className="text-base leading-relaxed text-neutral-400 max-md:max-w-full">
                      Are you sure you want to cancel your achievement?
                      </div>
                      <button
                        onClick={handleDelete}
                        className="md:w-[80%] mx-auto max-md:w-full px-3 py-4 mt-10 text-sm text-white whitespace-nowrap bg-[#FF1942] rounded-2xl "
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-20 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}

export default AddEditAchievements;
