import React, { useState, useEffect } from "react";
import HeaderDash from "./components/HeaderDash";
import { IoIosSearch } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import MentorFilter from "./components/MentorFilter";
import MentorDescription from "./components/MentorDescription";
import { CallingAxios, imageLink, truncateText } from "../auth/components/commonFunction";
import { UseGetMentors } from "../axios/axios";
import { toast } from "react-toastify";

function Mentors({ setActiveTab }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedMentor, setSelectedMentor] = useState('');
  const [allMentors, setAllMentors] = useState([]);

  useEffect(() => {
    callFetchMentors();
  }, [])

  const callFetchMentors = async () => {
    try {
      CallingAxios(
        await UseGetMentors()
          .then((response) => {
            if (response && response.status == 200) {
              console.log(response);
              setAllMentors(response.data);

            }
          })
          .catch((err) => {
            toast.error(err.message)
          })
      )
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="p-4 ">
      <HeaderDash setActiveTab={setActiveTab} />
      {selectedMentor ? (
        <MentorDescription singleMentor={selectedMentor} goBack={() => setSelectedMentor("")} />
      ) : (
        <div>
          <div className="flex flex-wrap items-start self-start mt-7 text-2xl font-semibold leading-none text-green-600">
            <img
              loading="lazy"
              src="/images/dashboard/quote-right.png"
              className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
            />
            <div className="flex-auto self-start max-md:max-w-full">
              Dream big, strive for greatness!
            </div>
            <img
              loading="lazy"
              src="/images/dashboard/quote-left.png"
              className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
            />
          </div>
          <div className="flex flex-col px-7 py-4 mt-4 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
            <div className="self-start font-bebas text-blue-600 text-3xl tracking-wide">
              Mentors List
            </div>
            <div className="flex justify-start gap-3 mt-5 text-sm text-black  max-md:max-w-full">
              <div className="flex gap-2 p-3 bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] min-w-[25vw]">
                <IoIosSearch className=" my-auto  text-2xl text-gray-400 " />
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="w-full outline-none border-none my-auto "
                  placeholder="Search Mentors..."
                />
              </div>
              <img
                onClick={() => setIsOpen(true)}
                loading="lazy"
                src="/images/dashboard/celebrate/filter.png"
                className="w-[45px] h-[45px] my-auto  cursor-pointer"
              />
            </div>
            <div
              style={{ scrollbarWidth: "none" }}
              className={`fixed top-0 right-0 h-full z-10 overflow-auto shadow-2xl  transition-transform duration-300 ease-in-out ${isOpen ? "translate-x-0" : "translate-x-full"
                }`}
            >
              <MentorFilter setIsOpen={setIsOpen} />
            </div>
            <div className="mt-6 max-md:max-w-full">
              <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-2 max-md:grid-cols-1 ">
                {allMentors.map((item, idx) => (
                  <div key={idx} onClick={() => setSelectedMentor(item)} className="flex flex-col max-md:w-full cursor-pointer">
                    <div className="flex flex-col items-start p-4 mx-auto w-full bg-white rounded-2xl border border-solid border-zinc-100 max-md:max-w-full">
                      <div className="flex gap-3.5">
                        <img
                          loading="lazy"
                          src={item.profilePicture ? imageLink(item.profilePicture) : item.profilePic}
                          className="object-contain rounded-full aspect-square w-[85px] border"
                        />
                        <div className="flex flex-col items-start my-auto">
                          <div className="flex flex-wrap  gap-x-3 ">
                            <div className="text-[15px] font-medium text-neutral-700">
                              {item.fullName}
                            </div>
                            <div className="flex gap-1 self-start text-sm my-auto text-purple-600 ">
                              <div className="">Adapter</div>
                              <img
                                loading="lazy"
                                src={"/images/dashboard/mentors/superman.png"}
                                className="object-contain w-[13px]"
                              />
                            </div>
                          </div>
                          <div className="text-sm mt-1 text-black text-opacity-40">
                            {item.userType}
                          </div>
                          <div className="flex gap-2 mt-2 text-sm  text-black">
                            <FaUser className="my-auto text-[#1D68FF]" />
                            <div>ID: {item.candidateId}</div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3.5 text-sm text-[#000000] opacity-[0.4]">
                        {truncateText(item.description, 20)}
                      </div>
                      <div className="grid grid-cols-3 gap-2.5 mt-3 text-xs tracking-normal">
                        {item.expertise.map((name, idxe) => (
                          <button key={idxe}
                            className="px-2 py-2 rounded-3xl text-blue-600 bg-gradient-to-r from-custom-l-blue to-custom-l-purple bg-[length:200%_100%]"
                          >
                            {name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}
export default Mentors;

const MentorsList = [
  {
    profilePic: "/images/dashboard/mentors/mentor1.png",
    name: "John Doe",
    profession: "UX Designer",
    adapter: "Adapter",
    img: "/images/dashboard/mentors/superman.png",
    id: "1234567",
    desc: "Lorem ipsum dolor sit amet consectetur. Convallis integer nec nisl massa. Feugiat quis pharetra volutpat elit. Sed lorem ",
    expert1: "Java Expert",
    expert2: "Python Expert",
  },
  {
    profilePic: "/images/dashboard/mentors/mentor2.png",
    name: "John Doe",
    profession: "Mobile Developer",
    adapter: "Adapter",
    img: "/images/dashboard/mentors/superman.png",
    id: "1234567",
    desc: "Lorem ipsum dolor sit amet consectetur. Convallis integer nec nisl massa. Feugiat quis pharetra volutpat elit. Sed lorem ",
    expert1: "Java Expert",
    expert2: "Python Expert",
  }, {
    profilePic: "/images/dashboard/mentors/mentor3.png",
    name: "John Doe",
    profession: "Customer Success Manager",
    adapter: "Adapter",
    img: "/images/dashboard/mentors/superman.png",
    id: "1234567",
    desc: "Lorem ipsum dolor sit amet consectetur. Convallis integer nec nisl massa. Feugiat quis pharetra volutpat elit. Sed lorem ",
    expert1: "Java Expert",
    expert2: "Python Expert",
  }, {
    profilePic: "/images/dashboard/mentors/mentor4.png",
    name: "John Doe",
    profession: "Digital Marketer",
    adapter: "Adapter",
    img: "/images/dashboard/mentors/superman.png",
    id: "1234567",
    desc: "Lorem ipsum dolor sit amet consectetur. Convallis integer nec nisl massa. Feugiat quis pharetra volutpat elit. Sed lorem ",
    expert1: "Java Expert",
    expert2: "Python Expert",
  }, {
    profilePic: "/images/dashboard/mentors/mentor5.png",
    name: "John Doe",
    profession: "Influencer",
    adapter: "Adapter",
    img: "/images/dashboard/mentors/superman.png",
    id: "1234567",
    desc: "Lorem ipsum dolor sit amet consectetur. Convallis integer nec nisl massa. Feugiat quis pharetra volutpat elit. Sed lorem ",
    expert1: "Java Expert",
    expert2: "Python Expert",
  }, {
    profilePic: "/images/dashboard/mentors/mentor2.png",
    name: "John Doe",
    profession: "Solution Specialist",
    adapter: "Adapter",
    img: "/images/dashboard/mentors/superman.png",
    id: "1234567",
    desc: "Lorem ipsum dolor sit amet consectetur. Convallis integer nec nisl massa. Feugiat quis pharetra volutpat elit. Sed lorem ",
    expert1: "Java Expert",
    expert2: "Python Expert",
  },
];
