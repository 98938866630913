import React, { useEffect, useState } from "react";
import { FaUser, FaSchool } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Subscriptions = () => {
  const [selectedPlan, setSelectedPlan] = useState("Individual");
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");

  const location = useLocation();
  const { subscription } = location.state || {};
  const navigate = useNavigate();

  // Set the default plan based on subscription state
  useEffect(() => {
    if (subscription === "school") {
      setSelectedPlan("Schools");
    } else if (subscription === "individual") {
      setSelectedPlan("Individual");
    }
  }, [subscription]);

  const handleProceedToPay = () => {
    if (!selectedPaymentOption) {
      alert("Please select a payment option.");
      return;
    }

    navigate("/proceed-payment", {
      state: { selectedPlan, selectedPaymentOption, subscription },
    });
  };

  return (
    <div
      style={{
        backgroundImage: "url(/images/subscription/subscription-bg.png)",
      }}
      className="relative min-w-[320px] min-h-[100vh] bg-fixed bg-top bg-cover bg-no-repeat"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-[#1D68FF] to-[#9A52DE97] opacity-75"></div>
      <div className="relative flex flex-col items-center justify-center min-h-full py-8 sm:py-16 px-4">
        <div className="flex flex-col md:flex-row justify-between items-end">
          <div className="text-white py-5">
            <h1 className="text-5xl font-bebas max-md:text-4xl tracking-[2.55px]">
              CHOOSE YOUR PLAN
            </h1>
            <p className="text-xs md:text-sm mt-1">
              Lorem ipsum dolor sit amet consectetur. Tellus ac sociis lobortis
              at fermentum.
            </p>
          </div>
          <img
            src="/images/subscription/student.png"
            alt="not found"
            className="w-3/4 md:w-1/3"
          />
        </div>
        <div className="bg-white shadow-lg rounded-lg py-10 overflow-hidden w-full max-w-5xl flex flex-col md:flex-row z-10">
          <div className="w-full md:w-[37%] px-10 my-auto pt-5">
            <div className="h-full">
              <div
                style={{ boxShadow: "0px 0px 31px 0px rgba(0, 0, 0, 0.07)" }}
                className={`p-4 border-2 flex justify-between rounded-3xl mb-4 cursor-pointer ${
                  selectedPlan === "Individual" || subscription === "individual"
                    ? "border-blue-500"
                    : ""
                } ${subscription === "school" ? "hidden" : ""}`}
                onClick={() => setSelectedPlan("Individual")}
              >
                <div className="flex flex-col justify-between text-[#919191]">
                  <h2 className="text-lg md:text-xl font-bold text-[#009A39]">
                    $49.00
                  </h2>
                  <p className="text-[#919191]">Yearly</p>
                  <p className="font-bold text-[#090D20] mt-2">Individual</p>
                  <p className="text-sm">This is an individual plan</p>
                </div>
                <FaUser className="text-[#1D68FF] bg-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
              </div>
              <div
                style={{ boxShadow: "0px 0px 31px 0px rgba(0, 0, 0, 0.07)" }}
                className={`p-4 border-2 rounded-3xl flex justify-between cursor-pointer ${
                  selectedPlan === "Schools" || subscription === "school"
                    ? "border-blue-500"
                    : ""
                } ${subscription === "individual" ? "hidden" : ""}`}
                onClick={() => setSelectedPlan("Schools")}
              >
                <div className="flex flex-col justify-between text-[#919191]">
                  <h2 className="text-lg md:text-xl font-bold text-[#009A39]">
                    $17.00
                  </h2>
                  <p className="">Yearly</p>
                  <p className="font-bold text-[#090D20] mt-2">Schools</p>
                  <p className="text-sm">This is per student plan</p>
                  <p className="text-xs text-[#F79E1B] font-[italic]">
                    Learn how you can register from school →
                  </p>
                </div>
                <FaSchool className="text-[#1D68FF] bg-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-[63%] bg-white px-10 max-md:mt-10 border-l-[1px]">
            <h2 className="text-2xl max-md:text-xl font-bebas tracking-[2px] text-green-600">
              TOTAL AMOUNT
            </h2>
            <p className="mt-2 text-gray-700 text-[15px] md:w-[70%]">
              {selectedPlan === "Individual"
                ? "As you have chosen Individual plan, the total amount for subscription is $49.00"
                : "As there are 17 students in the .csv file, the total amount for subscription is $289"}
              .
            </p>
            <h3 className="text-2xl max-md:text-xl font-bebas tracking-[2px] text-green-600 mt-8">
              CHOOSE PAYMENT OPTION
            </h3>
            <div className="mt-4">
              {paymentOptions.map((option) => (
                <div
                  key={option.name}
                  style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
                  className={`flex items-center justify-between p-4 mb-3 rounded-xl cursor-pointer ${
                    selectedPaymentOption === option.name
                      ? "border-2 border-blue-500"
                      : ""
                  }`}
                  onClick={() => setSelectedPaymentOption(option.name)}
                >
                  <div className="flex items-center">
                    <img
                      src={option.icon}
                      alt="not found"
                      className="my-auto"
                    />
                    <span className="text-gray-700 pl-2 my-auto">
                      {option.name}
                    </span>
                  </div>
                  <span className="text-[#FF9C1F] text-xs">
                    <FaGreaterThan />
                  </span>
                </div>
              ))}
            </div>
            <button
              onClick={handleProceedToPay}
              className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4"
            >
              Proceed to Pay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;

const paymentOptions = [
  {
    name: "Debit Card",
    icon: "/images/subscription/debit.png",
  },
  {
    name: "Credit Card",
    icon: "/images/subscription/credit.png",
  },
  { name: "Gpay", icon: "/images/subscription/g-pay.png" },
  {
    name: "Paypal",
    icon: "/images/subscription/paypal.png",
  },
];
