import React, { useState } from "react";
import { TiVideo } from "react-icons/ti";
import { GoDotFill } from "react-icons/go";


const ChatWithMentor = ({setChatMentor}) => {
  const [message, setMessage] = useState(""); // State for the input message
  const [messages, setMessages] = useState([
    // State for the list of chat messages
    {
      text: "Cool, let's talk about it later, shall we? This is going to be a huge!! We already  about it later, shall we? This is going to be a huge!! We already  sent you the details bro.",
      time: "15:30",
      type: "received", // 'received' or 'sent'
    },
    {
      text: "Ok.. Will send you and John the meeting invite on your mail.",
      time: "15:30",
      type: "received",
    },
  ]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      setMessages([
        ...messages,
        { text: message, time: "15:30", type: "sent" },
      ]);
      setMessage(""); // Clear the input field after sending
    }
  };

  return (
    <div className="flex flex-col md:p-4 max-md:mt-5 w-full rounded-3xl max-md:max-w-full">
      <img onClick={()=>setChatMentor(false)}
        loading="lazy"
        src="/images/dashboard/mentors/back.png"
        className="object-contain shrink-0 my-auto rounded-2xl aspect-square w-[55px]"
      />
      <div className="flex flex-col mt-4 max-md:max-w-full">
        <div
          style={{ boxShadow: " 0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
          className="flex flex-wrap gap-5 justify-between w-[100%] p-3 mx-auto text-lg text-black bg-white rounded-2xl max-md:max-w-full"
        >
          <div className="flex gap-4">
            <img
              loading="lazy"
              src="/images/dashboard/mentors/mentor.png"
              className="object-contain shrink-0 rounded-full aspect-square w-[60px] max-md:w-[50px]"
            />
            <div className="my-auto">
              <div className=" text-base max-md:text-[15px]">Wanda Dean</div>
              <div className="flex text-[14px] text-[#30C39E] gap-1">
                <GoDotFill className="my-auto text-[12px] max-md:text-[11px]" /> online
              </div>
            </div>
          </div>
        <TiVideo className="text-[#6480E4] p-2 border-[1px] border-[#EEEEEE] h-12 w-12 max-md:h-10 max-md:w-10 rounded-xl my-auto" />
        </div>
        <div className="self-center mt-3.5 text-sm text-zinc-500">
          Yesterday
        </div>
      </div>
      <div className="self-center mt-3 max-w-full md:mx-5">
        <div className="flex flex-col gap-4 text-[15px] max-md:text-sm ">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex flex-col ${
                msg.type === "sent"
                  ? "justify-end ml-20"
                  : "justify-start w-[70%]"
              } gap-2`}
            >
              <div
                className={`flex gap-2 max-md:gap-1 ${
                  msg.type === "sent" ? "flex justify-end" : ""
                }`}
              >
                {msg.type === "sent" ? null : (
                   <img
                   loading="lazy"
                   src="/images/dashboard/mentors/mentor.png"
                   className="rounded-full aspect-square w-[35px] h-[35px] mt-1"
                 />
                )}
                <div
                  className={`px-5 py-3 rounded-xl ${
                    msg.type === "sent"
                      ? "bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] text-white"
                      : "bg-slate-50 text-black"
                  }`}
                >
                  {msg.text}
                </div>
              </div>
              <div
                className={`text-sm ${
                  msg.type === "sent"
                    ? "text-stone-500 flex justify-end"
                    : "text-stone-500 pl-12"
                }`}
              >
                {msg.time}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap gap-5 p-4  max-md:p-2 justify-between self-center mt-10 max-w-full text-base leading-relaxed rounded-xl bg-neutral-100 text-stone-500 w-[100%]">
        <div className="flex gap-3 justify-between w-full">
         <div className="flex gap-3 max-md:gap-2">
          <img
            loading="lazy"
            src="/images/dashboard/mentors/emoji.png"
            className=" w-5 h-5 my-auto"
          />
          <input
            type="text"
            placeholder="Yeah, Me too! Let’s go out tonight."
            value={message}
            onChange={handleInputChange}
            className="outline-none border-none bg-neutral-100 md:w-[60vw]"
          />
         </div>
          <button
            onClick={handleSendMessage}
            className=" "
          >
             <img
            loading="lazy"
            src="/images/dashboard/mentors/send.png"
            className=" w-5 h-5 my-auto "
          />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWithMentor;
