import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { register } from "../../axios/axios";

function Individual() {
  const [rememberMe, setRememberMe] = useState(true);
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/", {});
  };

  const [showPasswords, setShowPasswords] = useState({
    password1: false,
    password2: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      schoolRef: "",
      password: "",
      confirmPass: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, "Invalid format")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPass: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      if (!rememberMe) {
        toast.error("Please accept terms and conditions");
        return;
      }
      await register({
        name: values.name,
        email: values.email,
        // schoolRef: values.schoolRef,

        password: values.password,
      })
        .then((response) => {
          console.log("register successful:", response);
          navigate("/otp", {
            state: {
              type: "individual",
              email: values.email,
              data: { button: "Submit" },
            },
          });
        })
        .catch((err) => {
          console.error("rigister failed:", err);
          // toast.error("Invalid email or password and other. Please try again.");
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleKeyDown = (e) => {
    // Prevent spacebar from being the first character
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  const scoolName = [
    {
      value: "10",
      label: "SPPU",
    },
    {
      value: "20",
      label: "xyz",
    },
    {
      value: "30",
      label: "qwe",
    },
    {
      value: "40",
      label: "asd",
    },
    {
      value: "50",
      label: "rty",
    },
    {
      value: "60",
      label: "ghj",
    },
    {
      value: "70",
      label: "yui",
    },
  ];

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { marginTop: 1.5 },
            "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after":
              {
                borderBottom: "none !important",
              },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
            "& .MuiFormLabel-root": {
              padding: "15px 3px 0px 21px",
              fontSize: "17px",
              color: "black",
            },
            "& .MuiInputBase-root": {
              padding: "10px 0px 3px 15px",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="name"
            label="Full Name"
            placeholder="John Doe"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <FaUser />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          {formik.touched.name && formik.errors.name && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.name}
            </FormHelperText>
          )}
          <TextField
            id="email"
            label="Email"
            placeholder="johndoe@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            onKeyDown={handleKeyDown}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 20px" }}>
                  <IoMdMail />
                </InputAdornment>
              ),
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {formik.touched.email && formik.errors.email && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.email}
            </FormHelperText>
          )}
          <FormControl
            fullWidth
            className="bg-white w-full rounded-xl"
            sx={{ marginTop: "10px" }}
          >
            <InputLabel
              variant="standard"
              htmlFor="school-native-select"
              shrink
              sx={{ fontSize: "14px", color: "#636363" }}
            >
              School Name
            </InputLabel>
            <NativeSelect
              name="schoolRef"
              value={formik.values.schoolRef}
              onChange={formik.handleChange}
              inputProps={{
                name: "schoolRef",
                id: "school-native-select",
              }}
              sx={{
                color: "#636363",
                fontSize: "14px",
                margin: "0px 10px 0px 1px",
              }}
            >
              {scoolName.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
              {/* <option value={20}>MIT</option>
              <option value={30}>SIMCA</option> */}
            </NativeSelect>
          </FormControl>
          {/* <TextField
            id="schoolDist"
            label="School District"
            placeholder="Clark County District"
            value={formik.values.schoolDist}
            InputLabelProps={{
              shrink: true,
              sx: { fontSize: "14px" },
            }}
            sx={{
              color: "#636363",
              fontSize: "14px",
              padding: "0px 10px 0px 1px",
            }}
            variant="standard"
            className="bg-white w-full rounded-xl pt-10"
            InputProps={{
              readOnly: true,
              sx: { fontSize: "14px" },
            }}
          /> */}
          <TextField
            id="password"
            variant="standard"
            label="Create New Password"
            placeholder="***********"
            type={showPasswords.password1 ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={handleKeyDown}
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password1")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password1 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="bg-white w-full rounded-xl pt-10"
            error={
              formik.touched.createPass && Boolean(formik.errors.createPass)
            }
          />
          {formik.touched.createPass && formik.errors.createPass && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.createPass}
            </FormHelperText>
          )}
          <TextField
            id="confirmPass"
            variant="standard"
            label="Confirm Password"
            placeholder="***********"
            type={showPasswords.password2 ? "text" : "password"}
            value={formik.values.confirmPass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="confirmPass"
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password2")}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswords.password2 ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className="bg-white w-full rounded-xl pt-10"
            error={
              formik.touched.confirmPass && Boolean(formik.errors.confirmPass)
            }
          />
          {formik.touched.confirmPass && formik.errors.confirmPass && (
            <FormHelperText sx={{ color: "red", mt: 0.5 }}>
              {formik.errors.confirmPass}
            </FormHelperText>
          )}
        </Box>
        <div className="flex gap-2 justify-center mt-6 max-w-full">
          <div className="flex flex-col p-0.5">
            <input
              type="checkbox"
              className="w-5 h-5 max-md:w-4 max-md:h-4"
              checked={rememberMe}
              onChange={() => setRememberMe((prev) => !prev)}
            />
          </div>
          <div className="text-base leading-6 ">
            I agree with
            <button className="text-blue-600 pl-1 cursor-pointer">
              <Link to="/terms-conditions">terms & conditions</Link>
            </button>
          </div>
        </div>
        <button
          type="submit"
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
        >
          Send OTP
        </button>
        <div className="flex justify-center mt-7  text-base text-slate-950">
          Already have an account?{" "}
          <button
            onClick={() => goToLogin()}
            className="font-medium text-blue-600 pl-1 cursor-pointer"
          >
            Login
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Individual;
