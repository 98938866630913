import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoIosChatbubbles } from "react-icons/io";
import { MdOutlineReport } from "react-icons/md";
import ChatWithMentor from "./ChatWithMentor";
import { toast } from "react-toastify";
import { CallingAxios } from "../../auth/components/commonFunction";
import { UseGetSingleMentor } from "../../axios/axios";

function MentorDescription({ singleMentor, goBack }) {
  const [showModal, setShowModal] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [chatMentor, setChatMentor] = useState(false);

  const [reviews, setReviews] = useState([
    {
      name: "John Doe",
      stars: "/images/dashboard/microcredentials/stars.png",
      date: "2 days ago",
      text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
      isEditable: false,
    },
    {
      name: "Jane Smith",
      stars: "/images/dashboard/microcredentials/stars.png",
      date: "5 days ago",
      text: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
      isEditable: false,
    },
    {
      name: "Alice Johnson",
      stars: "/images/dashboard/microcredentials/stars.png",
      date: "1 week ago",
      text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?",
      isEditable: false,
    },
  ]);
  const [newReview, setNewReview] = useState("");
  const [editingReviewIndex, setEditingReviewIndex] = useState(null);
  console.log(singleMentor);
  useEffect(() => {
    CallGetSingleMentor(singleMentor._id)
  }, [])


  const toggleShowReport = () => {
    setShowReport((prevState) => !prevState);
  };

  const handleAddReview = () => {
    if (newReview.trim() !== "") {
      if (editingReviewIndex !== null) {
        // Update existing review
        const updatedReviews = [...reviews];
        updatedReviews[editingReviewIndex] = {
          ...updatedReviews[editingReviewIndex],
          text: newReview,
          date: "Just now", // Update date to reflect change
        };
        setReviews(updatedReviews);
      } else {
        // Add new review
        const newReviewObject = {
          name: "John Doe", // Placeholder for name, replace with your name
          stars: "/images/dashboard/microcredentials/stars.png", // Placeholder for stars
          date: "Just now", // Placeholder for date
          text: newReview,
          isEditable: true, // Your reviews are editable
        };
        setReviews([newReviewObject, ...reviews]);
      }

      setNewReview("");
      setEditingReviewIndex(null);
      setShowModal(false);
    }
  };

  const handleEditReview = (index) => {
    if (reviews[index].isEditable) {
      setNewReview(reviews[index].text);
      setEditingReviewIndex(index);
      setShowModal(true);
    }
  };

  const handleReportSubmit = () => {
    setModalReport(false);
    setShowSuccessModal(true);
  };

  const CallGetSingleMentor = async () => {
    try {
      CallingAxios(
        await UseGetSingleMentor(singleMentor?.candidateId)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            toast.error(err.message)
          })
      )
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div>
      {chatMentor ? (
        <ChatWithMentor setChatMentor={setChatMentor} />
      ) : (
        <div className="p-4 max-md:max-w-full">
          <div className="flex gap-5 max-lg:flex-col">
            <div className="flex flex-col w-[64%] max-md:ml-0 max-lg:w-full">
              <div className="flex flex-col grow text-[#009A39] max-md:mt-10 max-md:max-w-full">
                <div className="cursor-pointer">
                  <button
                    onClick={() => goBack(false)}
                    className="text-4xl border-2 border-blue-600 text-blue-600 px-3 rounded-lg"
                  >
                    &lt;
                  </button>
                </div>
                <div className="flex flex-col rounded-none">
                  <div className="flex relative flex-col p-2 w-full rounded-xl min-h-[250px] max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/images/dashboard/mentors/mentorsDesc.png"
                      alt="Mentor Description"
                      className="object-fill absolute rounded-xl min-h-[250px] size-full"
                    />
                    <div className="relative pt-3 pl-3 pr-3 w-full rounded-none max-md:max-w-full">
                      <div className="flex gap-5 max-md:flex-col">
                        <div className="flex flex-col w-[30%] mt-3 max-md:ml-0 max-md:w-full">
                          <img
                            loading="lazy"
                            src="/images/dashboard/mentors/mentor.png"
                            alt="Mentor"
                            className="object-contain shrink-0 max-w-full my-auto rounded-xl aspect-square w-[206px] max-md:mt-10"
                          />
                        </div>
                        <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col items-start w-full text-white max-md:max-w-full">
                            <div className="relative self-end text-2xl">
                              <button
                                onClick={toggleShowReport}
                                className="self-end text-2xl"
                              >
                                ...
                              </button>

                              {showReport && (
                                <div className="flex flex-col absolute md:top-8 right-3 max-md:bottom-3 text-sm text-black shadow-2xl">
                                  <div className="flex gap-2 p-3 max-md:p-2 bg-white rounded-xl">
                                    <MdOutlineReport className="text-2xl text-blue-600" />
                                    <button
                                      onClick={() => setModalReport(true)}
                                      className="whitespace-nowrap my-auto"
                                    >
                                      Report This Mentor
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="text-xl font-semibold tracking-tight leading-none text-yellow-200">
                              John Doe
                            </div>
                            <div className="flex gap-7 items-start mt-1.5">
                              <div className="flex gap-1.5 text-[15px] tracking-normal leading-none whitespace-nowrap">
                                <div className="grow">Influencer</div>
                                <img
                                  loading="lazy"
                                  src="/images/dashboard/mentors/superman.png"
                                  alt="Badge"
                                  className="object-contain shrink-0 self-start mt-1 w-3.5 aspect-[0.87]"
                                />
                              </div>
                              <div className="flex gap-2 text-[15px] tracking-tight leading-none">
                                <FaUser />
                                <div>ID: 1234567</div>
                              </div>
                            </div>
                            <div className="mt-3 text-sm leading-5 max-md:max-w-full">
                              Lorem ipsum dolor sit amet consectetur. Nunc ut eu
                              a est viverra sodales cursus elementum. Nibh sed
                              ipsum maecenas cursus tempor.
                            </div>
                            <div className="flex gap-2.5 self-end mt-6 text-[11px] tracking-normal max-md:mr-1">
                              <div className="px-4 py-1.5 bg-white bg-opacity-20 rounded-[100px]">
                                Java Expert
                              </div>
                              <div className="px-4 py-1.5 bg-white bg-opacity-20 rounded-[100px]">
                                Python Expert
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="self-start mt-7 text-3xl max-md:text-2xl font-bebas tracking-[1.96px]">
                  About
                </div>
                <div className="mt-2.5 mr-8 text-[15px] leading-6 text-[#524B6B] max-md:mr-2.5 max-md:max-w-full">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventor
                </div>
                <div className="self-start mt-7 font-bebas text-3xl max-md:text-2xl tracking-[1.96px]">
                  Mentor’s Expertise
                </div>
                <div className="text-[#524B6B] text-[15px] pl-5 mt-3  max-md:max-w-full">
                  <ul className="list-disc list-outside leading-relaxed">
                    <li>
                      Sed ut perspiciatis unde omnis iste natus error sit.
                    </li>
                    <li>
                      Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                      amet, consectetur & adipisci velit.
                    </li>
                    <li>
                      Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                      amet, consectetur & adipisci velit.
                    </li>
                    <li>
                      Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                      amet, consectetur & adipisci velit. ipsum quia dolor sit
                      amet, consectetur & adipisci velit.
                    </li>
                  </ul>
                </div>
                <div className="flex justify-center mt-7 max-md:mt-10 max-md:max-w-full">
                  <button
                    onClick={() => setChatMentor(true)}
                    style={{
                      background:
                        "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                    }}
                    className="md:px-14 py-3.5 mt-5 w-fit flex gap-2 text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
                  >
                    <IoIosChatbubbles className="text-xl my-auto" />
                    Chat with Mentor
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-14 ml-1 max-md:ml-0 w-[36%] max-lg:w-full max-md:max-w-full">
              <div className="flex flex-col  max-md:max-w-full">
                <div className="self-start font-bebas text-3xl text-[#009A39] tracking-[1.96px]">
                  Ratings and Reviews
                </div>
                <div className=" p-3 w-full rounded-xl bg-[#F8F8F8] max-lg:max-w-full">
                  <div className="flex justify-between gap-5 max-md:flex-col">
                    <div className="flex flex-col w-[50%] max-md:w-full">
                      <div className="flex gap-2 my-auto text-neutral-700 max-md:mt-10">
                        <div className="text-3xl font-medium">3.4</div>
                        <div className="flex flex-col self-start mt-1 text-sm">
                          <img
                            loading="lazy"
                            src="/images/dashboard/microcredentials/stars.png"
                            className="w-32"
                          />
                          <div className="mt-1 text-[13px] max-md:mr-1.5">
                            Based on 3 reviews
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-[50%] max-md:w-full">
                      <div className="flex flex-col w-full max-md:mt-10">
                        <div className="flex gap-1 text-xs text-neutral-700">
                          <div>5 Star</div>
                          <div className="flex my-auto h-2 bg-yellow-400 rounded w-[100px]" />
                        </div>
                        <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                          <div>4 Star</div>
                          <div className="flex my-auto h-2 bg-yellow-400 rounded w-[80px]" />
                        </div>
                        <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                          <div>3 Star</div>
                          <div className="flex my-auto h-2 bg-yellow-400 rounded w-[60px]" />
                        </div>
                        <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                          <div>2 Star</div>
                          <div className="flex my-auto h-2 bg-yellow-400 rounded w-[40px]" />
                        </div>
                        <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                          <div> 1 Star</div>
                          <div className="flex my-auto h-2 bg-yellow-400 rounded w-[20px]" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  max-md:mt-10 max-md:max-w-full">
                <div
                  style={{ scrollbarWidth: "none" }}
                  className="flex flex-col mt-4 ml-1 max-md:ml-0 max-lg:w-full h-screen overflow-y-auto max-w-[100%] max-md:max-w-full review-container"
                >
                  {reviews.map((review, index) => (
                    <div key={index}>
                      <div className="flex flex-wrap gap-10 mt-4 max-w-full">
                        <div className="flex flex-col flex-1">
                          <div className="text-base font-medium text-gray-600">
                            {review.name}
                          </div>
                          <div className="self-start text-sm text-neutral-400">
                            {review.date}
                          </div>
                        </div>
                        <img
                          loading="lazy"
                          src={review.stars}
                          className="w-28 h-5"
                        />
                      </div>
                      <div className="mt-3.5 text-[15px] text-gray-600 max-md:mr-2.5 overflow-ellipsis  max-md:max-w-full">
                        {review.text}
                      </div>
                      {review.isEditable && (
                        <div className="flex gap-2.5 text-[15px] mt-1 text-blue-600">
                          <img
                            loading="lazy"
                            src="/images/dashboard/mentors/edit.png"
                            className="object-contain shrink-0 w-5.5 aspect-square"
                          />
                          <button
                            onClick={() => handleEditReview(index)}
                            className="basis-auto"
                          >
                            Edit Your Review
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex justify-center mt-5">
                  <button
                    style={{
                      boxShadow: "0px 0px 89px 0px rgba(0, 0, 0, 0.07)",
                    }}
                    onClick={() => setShowModal(true)}
                    className="md:px-14 py-3.5 w-full flex gap-2 text-base text-center text-blue-600 rounded-2xl max-md:px-5 max-md:max-w-full"
                  >
                    Write a review for mentor
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* review modal */}
          {showModal ? (
            <>
              <div
                style={{ scrollbarWidth: "none" }}
                className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto"
              >
                <div
                  className="absolute inset-0 bg-black opacity-50"
                  onClick={() => {
                    setShowModal(false);
                    setEditingReviewIndex(null);
                    setNewReview("");
                  }}
                ></div>
                {/*content*/}
                <div className="flex flex-col rounded-none md:w-[500px] max-md:mx-3 z-10">
                  <div className="flex flex-col p-5 w-full bg-white rounded-3xl max-md:px-5 max-md:max-w-full">
                    <div
                      onClick={() => setShowModal(false)}
                      className="self-end cursor-pointer"
                    >
                      <img
                        loading="lazy"
                        src="/images/dashboard/celebrate/cross.png"
                        className="object-contain aspect-square bg-gray-200 rounded-[30px] "
                      />
                    </div>
                    <div className="flex flex-wrap gap-7 -mt-5 self-end w-full max-md:max-w-full">
                      <div className="flex flex-col grow shrink-0 items-center basis-0 w-fit max-md:max-w-full">
                        <img
                          loading="lazy"
                          src="/images/dashboard/mentors/verify.png"
                          className="object-contain max-w-full aspect-square w-[80px]"
                        />

                        <div className="mt-1.5 text-2xl font-bebas tracking-wider leading-relaxed uppercase text-green-600">
                          {editingReviewIndex !== null
                            ? "UPDATE Review"
                            : "Write Review "}
                        </div>
                        <div className="self-stretch mt-1.5 text-[15px] leading-7 text-center text-neutral-400 max-md:max-w-full">
                          Lorem Ipsum is simply dummy text of the printing{" "}
                          <br />
                          and typesetting industry
                        </div>
                        <div className="flex gap-4 mt-3 max-w-full text-2xl font-medium leading-loose text-black w-[229px]">
                          <img
                            loading="lazy"
                            src="/images/dashboard/mentors/mentor5.png"
                            className="object-contain shrink-0 aspect-square rounded-full w-[50px]"
                          />
                          <div className="">
                            <div className="grow shrink self-start text-xl">
                              John Doe
                            </div>
                            <div className="text-sm font-normal">
                              UX Designer
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 text-[15px] font-medium leading-snug text-zinc-400">
                          Your Feedback
                        </div>
                        <img
                          loading="lazy"
                          src="/images/dashboard/microcredentials/stars.png"
                          className="object-contain mt-1.5 max-w-full aspect-[6.37] w-[250px]"
                        />
                      </div>
                    </div>
                    <textarea
                      className="w-full h-12 mt-5 pl-2 pt-3 border-none outline-none rounded-lg bg-zinc-300 bg-opacity-40 text-zinc-500 "
                      placeholder="Type your review here..."
                      value={newReview}
                      onChange={(e) => setNewReview(e.target.value)}
                    />

                    <button
                      onClick={handleAddReview}
                      className="self-center px-10 py-3 uppercase mt-8 max-w-full w-full text-base text-center text-white whitespace-nowrap rounded-xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]  max-md:px-5"
                    >
                      {editingReviewIndex !== null ? "Submit" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {/* report modal */}
          <div>
            {/* Report Modal */}
            {modalReport && (
              <div className="fixed inset-0 flex items-center justify-center overflow-y-auto max-md:mx-3 bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                  {/* Report Form Contents */}
                  <form onSubmit={handleReportSubmit}>
                    <div
                      onClick={() => setModalReport(false)}
                      className="self-end cursor-pointer flex justify-end"
                    >
                      <img
                        loading="lazy"
                        src="/images/dashboard/celebrate/cross.png"
                        className="object-contain aspect-square  bg-gray-200 rounded-[30px] "
                      />
                    </div>
                    {/* Your form inputs go here */}

                    <div className="flex flex-col items-center max-md:max-w-full">
                      <div className="flex flex-col self-stretch  w-full max-md:px-5 max-md:max-w-full">
                        <div className="flex flex-wrap gap-7 items-start w-full max-md:max-w-full">
                          <div className="flex flex-col grow shrink-0 self-end mt-2 basis-0 w-fit max-md:max-w-full">
                            <div className="self-center text-3xl font-bebas tracking-wider leading-relaxed text-amber-500 max-md:text-2xl">
                              REPORT MENTOR
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch mt-1 text-[15px] leading-7 text-center text-neutral-400 max-md:max-w-full">
                          Lorem Ipsum is simply dummy text of the printing{" "}
                          <br />
                          and typesetting industry
                        </div>
                        <div className="flex self-center gap-4 mt-3 max-w-full text-2xl font-medium leading-loose text-black w-[229px]">
                          <img
                            loading="lazy"
                            src="/images/dashboard/mentors/mentor5.png"
                            className="object-contain shrink-0 aspect-square rounded-full w-[50px]"
                          />
                          <div className="">
                            <div className="grow shrink self-start text-xl">
                              John Doe
                            </div>
                            <div className="text-sm font-normal">
                              UX Designer
                            </div>
                          </div>
                        </div>
                      </div>
                      <textarea
                        className="w-full mt-5 pl-2 pt-3  border-none outline-none rounded-lg bg-zinc-300 bg-opacity-40 text-zinc-500 "
                        placeholder="State your reason for reporting"
                        rows={4}
                      />
                      <button className="p-4 mt-8 max-w-full w-[70%] text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] ">
                        Report
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                  <div className="flex flex-col items-center max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/images/dashboard/mentors/verify.png"
                      className="object-contain max-w-full aspect-square w-[80px]"
                    />
                    <div className="mt-1.5 text-3xl text-[#009A39] font-bebas tracking-wider leading-relaxed max-md:text-2xl">
                      Reported Successfully
                    </div>
                    <div className="mt-1 text-[15px] leading-8 text-center text-neutral-400 max-md:max-w-full">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </div>
                    <button
                      onClick={() => goBack(false)}
                      className="self-center text-[#009A39] p-4 mt-8 w-[70%] max-w-full text-sm text-center rounded-xl border border-green-600 border-solid "
                    >
                      Go To Mentors List
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MentorDescription;
