import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext/AuthContext";
import AllModals from "../../AllModals";

function NotAuthenticationLayout({ children }) {

  const { logged, user } = useAuth();
  const navigate = useNavigate();
  const tkn = Cookies.get("Token");
  const [proceed, setProceed] = useState(false);
  console.log(logged);
  useEffect(() => {
    if (!tkn) {
      setProceed(true);
      // } else if (tkn && user.emailVerified) {
      //   setProceed(true);
      // } else {
      //   if (!user.emailVerified) {
      //     navigate("/otp");
      //   } else {
      //     navigate("/login");
      //   }
    }
  }, []);

  return (
    <>
      {proceed && !logged && (
        <AllModals>
          <div className="flex flex-col h-[100%] w-[100%]">{children}</div>
        </AllModals>
      )}
    </>
  );
}

export default NotAuthenticationLayout;
