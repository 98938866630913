import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

function EditAbout({ setEditAbout , onSaveAbout }) {
  const [inputText, setInputText] = useState("");
  const [textareaText, setTextareaText] = useState("");

  const getWordCount = (text) => {
    if (text.trim() === "") return 0;
    return text.trim().split(/\s+/).length;
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    const wordCount = getWordCount(text);

    if (wordCount <= 12) {
      setInputText(text);
    }
  };

  const handleTextareaChange = (e) => {
    const text = e.target.value;
    const wordCount = getWordCount(text);

    if (wordCount <= 100) {
      setTextareaText(text);
    }
};
const handleSave = () => {
  onSaveAbout({
    tagLine: inputText,
    aboutMe : textareaText,
  });
  setEditAbout(false)
}
  return (
    <div className="flex flex-col p-5 mt-6 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
      <div className="flex gap-4 justify-between items-start self-start max-w-full">
        <div
          onClick={() => setEditAbout(false)}
          style={{ boxShadow: "0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
          className="bg-white rounded-full p-3 max-md:p-2 cursor-pointer"
        >
          <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
        </div>
        <div className="self-start mt-2 text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
        Edit About
        </div>
      </div>
      <div className="self-start ml-[62px] text-sm leading-loose text-neutral-400 max-md:ml-2.5">
      In this section you can write about your skills, experiences and achievements. 
      </div>
      <div className="px-24 max-md:px-0">
        <div className="w-full mx-auto">
          <div className="mt-10 max-md:mt-5">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="p-3 rounded-lg"
            >
              <div className="font-semibold text-base">Tagline</div>
              <input
                type="text"
                className="w-full rounded-md border-none outline-none text-base "
                placeholder="In this section, add your title"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
            </div>
            <div className="text-gray-500 text-sm text-right mt-1">
              {getWordCount(inputText)}/12
            </div>
          </div>
          <div className="mt-7 max-md:mt-5">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="p-3 rounded-lg"
            >
              <div className="font-semibold text-base">About You</div>
              <textarea
                className="w-full outline-none border-none"
                placeholder=""
                value={textareaText}
                onChange={(e) => setTextareaText(e.target.value)}
                rows={4}
              />
            </div>
            <div className="text-gray-500 text-sm text-right mt-1">
              {getWordCount(textareaText)}/100
            </div>
          </div>
        </div>
        <div className="flex gap-6 self-center mt-9 max-w-full text-base text-center text-white whitespace-nowrap max-md:gap-3 w-full">
          <button onClick={()=>handleSave()} className="p-4 max-w-full w-full text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditAbout;
