import * as React from "react";
import HeaderDash from "../HeaderDash";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaArrowRight } from "react-icons/fa6";

const Entrepreneurship = ({setActiveTab}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [isFullHeight, setIsFullHeight] = React.useState(false); // State to manage full height toggle

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleHeight = () => {
    setIsFullHeight((prev) => !prev);
  };

  return (
    <div className="p-4 mb-5 min-w-[320px] bg-gray-50">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="px-2">
        {/* Goals Section */}
        <Accordion
          style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="shadow-md rounded-lg mb-4 mt-8 border-none outline-none  bg-white"
          sx={{
            "&:before": {
              display: "none", // Remove the default border line before the accordion
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-red-500" />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div className="flex gap-2 items-center">
              <img
                src="/images/dashboard/career-planning/goal.png"
                alt="GOALS"
                className="w-10"
              />
              <Typography>
                {" "}
                <div className="font-bebas text-xl text-[#009A39]">GOALS</div>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{ scrollbarWidth: "none" }}
              className={`transition-max-height duration-300 overflow-y-auto ${
                isFullHeight ? "max-h-full" : "max-h-[200px]"
              }`}
            >
              <Typography className="">
                <div className="flex flex-col max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Equip students with essential entrepreneurial skills
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    such as business planning, financial management, marketing,
                    sales, and leadership to prepare them for successful
                    entrepreneurship or business roles.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Creativity & Innovation
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Foster a culture of creativity, critical thinking,
                    problem-solving, and innovation encouraging students to
                    develop unique and viable business ideas and solutions
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Equip students with essential entrepreneurial skills
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    such as business planning, financial management, marketing,
                    sales, and leadership
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Business Acumen and Knowledge
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Provide students with a strong foundation in business acumen
                    including understanding market dynamics, business
                    operations, legal considerations, and effective
                    decision-making.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Financial Literacy and Management:
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Enhance students' financial literacy teaching them important
                    financial management skills budgeting, funding strategies,
                    and risk assessment ensure the sustainability of their
                    ventures
                  </div>
                </div>
              </Typography>{" "}
              {/* Add additional content as needed */}
            </div>
            <button
              onClick={toggleHeight}
              className="text-center w-full text-gray-600 mt-4"
            >
              {isFullHeight ? "SHOW LESS" : "READ MORE"}
            </button>
          </AccordionDetails>
        </Accordion>

        {/* Outcomes Section */}
        <Accordion
          style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="shadow-md rounded-lg mt-3 border-none outline-none  bg-white"
          sx={{
            "&:before": {
              display: "none", // Remove the default border line before the accordion
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-red-500" />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div className="flex gap-2 items-center">
              <img
                src="/images/dashboard/career-planning/outcomes.png"
                alt="GOALS"
                className="w-10"
              />
              <Typography>
                {" "}
                <div className="font-bebas text-xl text-[#009A39]">
                  OUTCOMES
                </div>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{ scrollbarWidth: "none" }}
              className={`transition-max-height duration-300 overflow-y-auto ${
                isFullHeight ? "max-h-full" : "max-h-[200px]"
              }`}
            >
              <Typography className="">
                <div className="flex flex-col max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Skill Development for Future Entrepreneurs:
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Equip students with essential entrepreneurial skills and
                    knowledge necessary to successfully start, manage, and grow
                    their own businesses.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Job Readiness for Business Roles
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Prepare students for roles within existing businesses,
                    helping them develop a strong understanding of business
                    operations, innovation, and leadership.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Economic Growth and Innovation
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Foster a culture of innovation and economic growth within
                    the community by encouraging entrepreneurship and supporting
                    the development of new businesses and startups
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Meeting Workforce Needs
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    •Address the evolving needs of the job market by producing a
                    skilled workforce with a keen understanding of business
                    principles and entrepreneurial practices.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Community Development and Sustainability
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    •Empower students to contribute to their communities by
                    creating businesses that meet local needs, generate
                    employment, and stimulate economic development.
                  </div>
                </div>
              </Typography>{" "}
              {/* Add additional content as needed */}
            </div>
            <button
              onClick={toggleHeight}
              className="text-center w-full text-gray-600 mt-4"
            >
              {isFullHeight ? "SHOW LESS" : "READ MORE"}
            </button>
          </AccordionDetails>
        </Accordion>

        {/* Skills & Knowledge Section */}
        <Accordion
          style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="shadow-md rounded-lg mt-3 border-none outline-none  bg-white"
          sx={{
            "&:before": {
              display: "none", // Remove the default border line before the accordion
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-red-500" />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <div className="flex gap-2 items-center">
              <img
                src="/images/dashboard/career-planning/skill.png"
                alt="GOALS"
                className="w-10"
              />
              <Typography>
                {" "}
                <div className="font-bebas text-xl text-[#009A39]">
                  Skills & Knowledge for Students to Gain
                </div>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{ scrollbarWidth: "none" }}
              className={`transition-max-height duration-300 overflow-y-auto ${
                isFullHeight ? "max-h-full" : "max-h-[200px]"
              }`}
            >
              <Typography className="">
                <div className="flex flex-col max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Skill Development for Future Entrepreneurs
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Equip students with essential entrepreneurial skills and
                    knowledge necessary to successfully start, manage, and grow
                    their own businesses.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Job Readiness for Business Roles
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Prepare students for roles within existing businesses,
                    helping them develop a strong understanding of business
                    operations, innovation, and leadership.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Economic Growth and Innovation
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    Foster a culture of innovation and economic growth within
                    the community by encouraging entrepreneurship and supporting
                    the development of new businesses and startups
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Meeting Workforce Needs
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    •Address the evolving needs of the job market by producing a
                    skilled workforce with a keen understanding of business
                    principles and entrepreneurial practices.
                  </div>
                </div>
              </Typography>
              <Typography className="text-gray-700 ">
                <div className="flex flex-col mt-4 max-md:max-w-full">
                  <div className="self-start text-base leading-snug text-indigo-950 max-md:max-w-full">
                    Community Development and Sustainability
                  </div>
                  <div className="mt-1 text-sm leading-6 text-neutral-400 max-md:max-w-full">
                    •Empower students to contribute to their communities by
                    creating businesses that meet local needs, generate
                    employment, and stimulate economic development.
                  </div>
                </div>
              </Typography>{" "}
              {/* Add additional content as needed */}
            </div>
            <button
              onClick={toggleHeight}
              className="text-center w-full text-gray-600 mt-4"
            >
              {isFullHeight ? "SHOW LESS" : "READ MORE"}
            </button>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="flex mt-10 mx-4 justify-center items-center">
        <button className=" flex gap-3 bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]  text-white px-5 py-3 rounded-lg hover:bg-blue-600">
          The 5 Steps to Enlisting in the Military
          <FaArrowRight className="my-auto text-xl" />
        </button>
      </div>
    </div>
  );
};

export default Entrepreneurship;
