import * as React from "react";
import { IoIosArrowUp } from "react-icons/io";

function ClgOpFilter({setIsOpen}) {
  return (
    <div className="flex flex-col items-start p-10 bg-white min-h-screen">
      <div className="flex gap-5 justify-between self-stretch w-full max-md:max-w-full">
        <div className="my-auto text-2xl font-bebas tracking-widest text-neutral-700">
          FILTER
        </div>
        <img onClick={() => setIsOpen(false)}
          loading="lazy"
          src="/images/dashboard/celebrate/cross.png"
          className=" w-[34px] bg-gray-50 rounded-full cursor-pointer"
        />
      </div>
      <div className="mt-5 text-xl font-bebas tracking-widest text-green-600">
      STATE
      </div>
      <div className="flex gap-5 justify-between self-stretch px-4 py-4 mt-2.5 text-[15px] text-black whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)]  max-md:max-w-full">
        <div className="">North Calorina</div>
        <IoIosArrowUp className="my-auto" />
      </div>
      <div className="mt-5 text-xl font-bebas tracking-widest text-green-600">
      Program of Study
      </div>
      <div className="flex gap-5 justify-between self-stretch px-4 py-4 mt-2.5 text-[15px] text-black whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)]  max-md:max-w-full">
        <div className="">Information Technology</div>
        <IoIosArrowUp className="my-auto" />
      </div>
    
      <div className="flex flex-wrap gap-5 self-center md:mt-48 max-md:mt-10 max-md:max-w-full">
        <button className="px-7 py-3 text-[15px] text-center whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.13)] text-neutral-400 max-md:px-5">
          Reset
        </button>
        <button
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-7 py-3 text-[15px]  tracking-wider text-white uppercase rounded-xl shadow-[0px_4px_62px_rgba(153,171,198,0.18)] w-fit max-md:px-5"
        >
          Apply Now
        </button>
      </div>
    </div>
  );
}

export default ClgOpFilter
