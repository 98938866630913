// import logo from './logo.svg';
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import SignUp from "./auth/SignUp";
import ForgotPass from "./auth/ForgotPass";
import Otp from "./common/Otp";
import CreatePass from "./auth/CreatePass";
import TermsCondition from "./auth/TermsCondition";
import Subscriptions from "./subscription/Subscriptions";
import Login from "./auth/Login";
import ProceedPayment from "./subscription/ProceedPayment";
import Congratulation from "./subscription/Congratulation";
import AdminIndex from "./dashboard/AdminIndex";
import ReportProcessingPlan from "./dashboard/components/ReportProcessingPlan";
import Main from "./dashboard-student/Main";
import AuthContext from "./authContext/AuthContext";
import AuthenticLayout from "./wrapper/authenticationLayout/AuthenticLayout";
import NotAuthenticationLayout from "./wrapper/authenticationLayout/NotAuthenticationLayout";
import { useEffect } from "react";
import DeleteAccount from "./delete-account/DeleteAccount";
import AuthenticLayoutSchool from "./wrapper/authenticationLayout/AuthenticLayoutSchool";
import AllModals from "./AllModals";
function App() {

  // const [forgot ,setForgot]=useState(Forgot)

  return (
    <div>
      <AuthContext >
        {/* <AllModals> */}
          <Router>
            <Routes>
              <Route
                path="/login"
                element={
                  <NotAuthenticationLayout>
                    <Login />
                  </NotAuthenticationLayout>
                }
              />

              <Route
                path="/signUp"
                element={
                  <NotAuthenticationLayout>
                    <SignUp />
                  </NotAuthenticationLayout>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <NotAuthenticationLayout>
                    <ForgotPass />
                  </NotAuthenticationLayout>
                }
              />
              <Route path="/otp" element={<Otp />} />
              <Route path="/create-password" element={<CreatePass />} />
              <Route path="/terms-conditions" element={<TermsCondition />} />

              <Route
                path="/subscriptions"
                element={
                  <AuthenticLayout>
                    <Subscriptions />
                  </AuthenticLayout>
                }
              />
              <Route
                path="/proceed-payment"
                element={
                  <AuthenticLayout>
                    <ProceedPayment />
                  </AuthenticLayout>
                }
              />
              <Route
                path="/congratulation"
                element={
                  <AuthenticLayout>
                    <Congratulation />
                  </AuthenticLayout>
                }
              />
              {/* path="/admin-index" */}
              <Route
                path="/"
                element={
                  <AuthenticLayout>
                    <AdminIndex />
                  </AuthenticLayout>
                }
              />
              <Route
                path="/school-portal"
                element={
                  <AuthenticLayoutSchool>
                    <Main />
                  </AuthenticLayoutSchool>
                }
              />
              <Route
                path="/delete-account"
                element={
                  // <AuthenticLayout>
                  <DeleteAccount />
                  // </AuthenticLayout>
                }
              />
            </Routes>
          </Router>
        {/* </AllModals> */}
      </AuthContext>
    </div>
  );
}

export default App;
