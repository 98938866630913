import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { FaUser } from "react-icons/fa";

import HeaderDash from "../dashboard/components/HeaderDash";
import MyProfile from "../dashboard/profile-components/MyProfile";
import { useAuth } from "../authContext/AuthContext";
import { CallingAxios, imageLink } from "../auth/components/commonFunction";
import { UseGetMentors, UseSendInvitation } from "../axios/axios";
import { toast } from "react-toastify";

const DashboardStud = () => {
  const { school, loading, setLoading } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [AllMentors, setAllMentors] = useState();
  const [selectedMentor, setSelectedMentor] = useState();
  useEffect(() => {
    callFetchMentors();
  }, [])


  const handleSendInvite = async (id) => {
    try {
      const result = await CallingAxios(UseSendInvitation(id));
    } catch (error) {
      toast.error(error.message);
    }
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 2000); // Hide modal after 3 seconds
  };

  const callFetchMentors = async () => {
    try {
      const result = await CallingAxios(UseGetMentors());
      if (result && result.status == 200) {
        setAllMentors(result.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div>
      {selectedMentor ? (
        <MyProfile readOnly={true} />
      ) : (
        <main className="p-4 min-w-[320px]">
          <HeaderDash />
          <div>
            <div className="flex flex-wrap items-start self-start ml-6 mt-10 w-fit text-2xl font-semibold leading-none text-green-600">
              <img
                loading="lazy"
                src="/images/dashboard/quote-right.png"
                className="object-contain shrink-0 self-start -mt-3  aspect-square w-[25px]"
              />
              <div className="flex-auto self-start max-md:max-w-full">
                Dream big, strive for greatness!
              </div>
              <img
                loading="lazy"
                src="/images/dashboard/quote-left.png"
                className="object-contain shrink-0 self-end mt-4 aspect-square w-[25px]"
              />
            </div>
            <div className="flex flex-col px-10 py-7 mt-5 w-full text-sm bg-white rounded-xl shadow-2xl max-md:px-5 max-md:mr-3 max-md:max-w-full">
              <div className="self-start text-3xl font-bebas text-blue-600 tracking-[1px] max-md:text-2xl">
                {school?.name}
              </div>
              <div className="text-[#919191] md:mt-1">
                {school?.district}
              </div>
              <div className="flex gap-2 mt-5">
                <div className="flex justify-between h-fit my-auto  pl-4 pr-1 py-3  md:w-[40%] text-sm text-black bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] max-md:max-w-full">
                  <div className="flex gap-2   ">
                    <IoIosSearch className=" my-auto  text-2xl text-gray-400 max-md:text-xl " />
                    <input
                      type="search"
                      name="search-form"
                      id="search-form"
                      className="w-full outline-none border-none my-auto "
                      placeholder="Search feed "
                    />
                  </div>
                </div>
                <img
                  loading="lazy"
                  src="/images/dashboard/celebrate/filter.png"
                  className="w-[45px] h-[45px] my-auto  cursor-pointer max-md:w-[40px] max-md:h-[40px]"
                />
              </div>
              <div className="flex gap-7 mt-10 flex-wrap max-md:gap-4">
                {AllMentors?.map((singleMentor, idx) => (
                  <div key={idx} onClick={() => setSelectedMentor(singleMentor)} className="flex flex-col items-start p-1 max-w-full bg-white rounded-2xl border border-solid border-zinc-100 w-[170px] max-sm:w-full ">
                    <img
                      loading="lazy"
                      src={singleMentor.profilePicture ? imageLink(singleMentor.profilePicture) : "/images/stud-dashboard/profile-pic.png"}
                      className="object-contain self-stretch w-full rounded-2xl "
                    />
                    <div className="mt-3 pl-1 text-[15px] tracking-tight leading-none text-neutral-700">
                      {singleMentor.fullName}
                    </div>
                    <div className="flex gap-1 mt-1 pl-1">
                      <div className="text-gray-400 text-[14px]">
                        {singleMentor.userType}
                      </div>
                      <img
                        loading="lazy"
                        src="/images/stud-dashboard/superman.png"
                        className="object-contain shrink-0 my-auto self-start w-3 aspect-[0.86]"
                      />
                    </div>
                    <div className="flex gap-2 mt-3 pl-1">
                      <FaUser className="my-auto text-blue-600" />
                      <div>ID: {singleMentor.candidateId}</div>
                    </div>
                    <button
                      onClick={handleSendInvite}
                      className="self-stretch p-3 mx-1 mb-1 mt-4 text-xs text-center text-white bg-blue-600 rounded-xl shadow-[0px_4px_62px_rgba(153,171,198,0.18)] "
                    >
                      Send Invite
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {showModal ? (
              <>
                <div className="justify-center items-center  flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg mx-5 relative flex flex-col bg-white outline-none focus:outline-none max-w-[400px]">
                      <div className=" px-5 py-5 ">
                        <div className="flex justify-center">
                          <img
                            src="/images/stud-dashboard/request-send.png"
                            alt="exercise"
                            className="w-20"
                          />
                        </div>
                        <div>
                          <div className="z-10 self-center uppercase flex justify-center text-4xl max-md:text-2xl font-bebas tracking-[1px] mt-4 text-green-600">
                            SUCCESS
                          </div>
                          <div className="mx-auto text-center mt-3 text-[15px] self-center  text-[#8C8C8C]">
                            Invite sent successfully. <br />
                            The student will receive the credentials in their
                            email ID
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </div>
        </main>
      )}
    </div>
  );
};

export default DashboardStud;
