import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slick1 = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          color: "red",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px", padding: "0px", display: "flex", justifyContent: "center" }}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`dot ${i === 0 ? 'active' : ''}`} // Add 'active' class to the active dot
        style={{
          width: "18px", // Outer ring size
          height: "18px", // Outer ring size
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 6px", // Gap between dots
          position: "relative",
        }}
      >
        <div
          className="ring"
          style={{
            width: "18px", // Outer ring size
            height: "18px", // Outer ring size
            borderRadius: "50%",
            border: "2px solid #B4B6B8", // Ring color and width
            position: "absolute",
            top: "0",
            left: "0",
            visibility: i === 0 ? 'visible' : 'hidden', // Show ring only for active dot
          }}
        />
        <div
          className="inner-dot"
          style={{
            width: "10px", // Inner dot size
            height: "10px", // Inner dot size
            backgroundColor: "white", // Inner dot color
            borderRadius: "50%",
            position: "relative",
            zIndex: 1, // Ensure inner dot is above the ring
          }}
        />
      </div>
    ),
  };

  return (
    <div className="w-full text-white pb-20 pr-16 tracking-wider">
      <Slider {...settings}>
        <div>
          <img src="/images/auth/quotes.png" alt="quote" className="pl-0 w-[90px]" />
          <div style={{ fontStyle: "italic" }} className="pl-10">
            Lorem ipsum dolor sit amet consectetur. Cursus faucibus amet massa amet semper.
          </div>
          <div className="pl-10 text-[#FF9C1F] font-medium mt-2">Ariel Tungsten</div>
        </div>
        <div>
          <img src="/images/auth/quotes.png" alt="quote" className="pl-0 w-[90px]" />
          <div style={{ fontStyle: "italic" }} className="pl-10">
            Lorem ipsum dolor sit amet consectetur. Cursus faucibus amet massa amet semper.
          </div>
          <div className="pl-10 text-[#FF9C1F] font-medium mt-2">Ariel Tungsten</div>
        </div>
        <div>
          <img src="/images/auth/quotes.png" alt="quote" className="pl-0 w-[90px]" />
          <div style={{ fontStyle: "italic" }} className="pl-10">
            Lorem ipsum dolor sit amet consectetur. Cursus faucibus amet massa amet semper.
          </div>
          <div className="pl-10 text-[#FF9C1F] font-medium mt-2">Ariel Tungsten</div>
        </div>
      </Slider>
    </div>
  );
};

export default Slick1;
