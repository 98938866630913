import React, { useState, useRef, useEffect } from "react";
import HeaderDash from "./components/HeaderDash";
import CommentSection from "./components/CommentSection";
import { IoIosSearch } from "react-icons/io";
import { GoPencil } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import Filter from "./components/Filter";
import { CallingAxios, HandleModal, imageLink } from "../auth/components/commonFunction";
import { UseGetPost } from "../axios/axios";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { PiApplePodcastsLogo } from "react-icons/pi";

function Celebrate({ setActiveTab }) {
  const [postType, setPostType] = useState("all");  // all and user
  const [page, setPage] = useState(1);
  const [showModalDeletePost, setShowModalDeletePost] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const postRef = useRef();
  const divRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedPost, setSelectedPost] = useState("");
  const [allPosts, setAllPosts] = useState([]);
  const [inputComment, setInputComment] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);

  const emojis = ['😊', '😂', '😍', '😢', '😠'];

  // useEffect(() => {
  //   const outside = (e) => {
  //     if (divRef.current && !divRef.current.contains(e.target)) {
  //       setActiveId(null);
  //     }
  //   };
  //   document.addEventListener('click', outside);
  //   return () => {
  //     document.removeEventListener('click', outside);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Check if the click is outside of the postRef
  //     if (postRef.current && !postRef.current.contains(event.target)) {
  //       setActiveId(null);
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (postType === 'user') {
      CalGetMyPosts();
    } else {
      CallGetPosts();
    }
  }, [page, postType])

  const CallGetPosts = async () => {
    await CallingAxios(UseGetPost(page, 10)).then((response) => {
      if (response && response.status == 200) {
        // console.log(response.posts);
        setAllPosts((prevPosts) => [...prevPosts, ...response.posts]);
      }
    }).catch((err) => {
      toast.error(err.message || "Some Error on post fetching");
    })
  }
  const handleInputChange = (e) => {
    setInputComment(e.target.value);
  };
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputComment.trim()) {
      const newComment = {
        img: '/images/dashboard/user4.png', // Placeholder for user's profile image
        name: 'You', // Placeholder for user's name
        time: 'Just now',
        description: inputComment,
      };
      setInputComment(''); // Clear input field
    }
  };

  // Handle emoji click
  const handleEmojiClick = (emoji) => {
    setInputComment(inputComment + emoji);
    setShowEmojis(false); // Hide emoji list after selection
  };

  const CalGetMyPosts = async () => {
    await CallingAxios(UseGetPost(page, 10)).then((response) => {
      console.log(response.posts);
      if (response && response.status == 200) {
        setAllPosts((prevPosts) => [...prevPosts, ...response.posts]);
      }
    }).catch((err) => {
      toast.error(err.message || "Some Error on post fetching");
    })
  }

  const comments = Array(5).fill({
    img: 'https://via.placeholder.com/40',
    createdAt: new Date(),
    description: 'This is a sample comment. ' + 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. '.repeat(2), // Long comment for testing
  });

  return (
    <div className="flex flex-col p-4 w-full rounded-3xl bg-zinc-50 max-md:px-5 max-md:max-w-full">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="flex flex-wrap gap-5 mt-7 h-fit justify-between max-md:flex-col w-full  max-md:max-w-full">
        <div className="flex justify-between h-fit  pl-4 pr-1 py-1 md:w-[40%] text-sm text-black bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.05)] max-md:max-w-full">
          <div className="flex gap-2 w-[80vw] ">
            <IoIosSearch className=" my-auto  text-2xl text-gray-400 " />
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="w-full outline-none border-none my-auto "
              placeholder="Search feed "
            />
          </div>
          <img
            onClick={() => HandleModal(true, "new", "post")}
            loading="lazy"
            src="/images/dashboard/celebrate/filter.png"
            className="w-[45px] h-[45px] my-auto  cursor-pointer"
          />
        </div>
        <div
          style={{ scrollbarWidth: "none" }}
          className={`fixed top-0 right-0 h-full z-10 overflow-auto shadow-2xl  transition-transform duration-300 ease-in-out ${isOpen ? "translate-x-0" : "translate-x-full"
            }`}
        >
          <Filter setIsOpen={setIsOpen} />
        </div>
        <div className="flex flex-wrap  gap-5 text-base  max-md:mt-5 text-center max-md:max-w-full" >
          <button
            onClick={() => {
              setAllPosts([]);
              setPostType("user");
            }}
            className={`flex flex-auto text-[#1D68FF] justify-center items-center my-auto px-10 py-3 rounded-xl border border-blue-600 border-solid } ${postType === 'user' ? "hidden" : ""
              }`}
          >
            View My Posts
          </button>
          <button
            onClick={() => HandleModal(true, "new", "post")}
            style={{
              background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
            }}
            className="flex flex-auto gap-2.5 justify-center items-center px-7 py-3 text-white rounded-xl  max-md:px-5"
          >
            <div className="text-2xl">+</div>
            <div className=" my-auto">Create New Post</div>
          </button>
        </div>
      </div>
      <div className="mt-7 max-md:max-w-full">
        <div className="flex gap-5 max-lg:flex-col">
          {/* <div className="flex flex-col w-[34%] max-md:ml-0 max-lg:w-full">
            <CommentSection data={allPosts[0]} />
          </div> */}
          <div className="flex flex-col max-md:ml-0 max-lg:w-full">
            <div className="flex flex-col w-full max-md:mt-5 max-md:max-w-full">
              <div className="max-md:mr-0.5 max-md:max-w-full">
                <div
                  className="grid grid-cols-3 max-md:grid-cols-1 gap-4"

                >
                  {allPosts.map((item) => {
                    return (
                      <div ref={postRef} className={`${selectedPost?._id == item?._id && "col-span-1 row-span-2"} flex flex-col  max-md:w-full`}>
                        <div className="flex flex-col items-start p-3.5 mx-auto w-full text-black bg-white rounded-3xl shadow-2xl max-md:max-w-full">
                          <div className="flex  relative justify-between items-start w-full">
                            <div className="flex gap-3">
                              <img
                                loading="lazy"
                                src={item?.profileInfo?.profilePhoto ? imageLink(item?.profileInfo?.profilePhoto) : "/images/dashboard/celebrate/profilePic.png"}
                                className=" rounded-full w-10 h-10
                                "
                              />
                              <div className="flex flex-col my-auto">
                                <div className="self-start text-[15px] font-medium">
                                  {item?.profileInfo?.name}
                                </div>
                                <div className="text-xs">{moment(item?.createdAt).fromNow()}</div>
                              </div>
                            </div>
                            {item._id == activeId && (
                              <div className="absolute right-0 top-10">
                                <div className="flex flex-col text-sm ">
                                  <div className="flex flex-col items-start px-4 py-2.5 w-full bg-white rounded-2xl border border-solid border-neutral-200">
                                    <button id="noCloseButton" onClick={() => HandleModal(true, "new", "post")} className="flex gap-2.5 cursor-pointer text-neutral-700"><GoPencil className="text-lg" />Edit Post</button>
                                    <button onClick={() =>
                                      setShowModalDeletePost(true)
                                    } className="flex gap-2.5 mt-2 cursor-pointer text-rose-600" id="noCloseButton"><RiDeleteBinLine className="text-lg" /> Delete Post</button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {postType === 'user' &&
                              <div
                                ref={divRef}
                                className="text-3xl cursor-pointer"
                                onClick={() => setActiveId(item?._id)}
                              >
                                ...
                              </div>
                            }
                          </div>
                          <img
                            loading="lazy"
                            src={imageLink(item.media[0])}
                            className="object-contain rounded-2xl self-stretch mt-4 w-full aspect-[1.82] max-md:max-w-full"
                          />
                          <div className="mt-2.5 text-sm leading-6 text-neutral-700">
                            {item.content}
                          </div>
                          <div className="flex gap-5 mt-3 leading-none whitespace-nowrap">
                            <div className="flex gap-1.5 text-sm">
                              <img
                                loading="lazy"
                                src={"/images/dashboard/celebrate/hand.png"}
                                className="object-contain shrink-0 aspect-square -translate-y-[2px] w-[26px]"
                              />
                              <div className="my-auto">{item.handCount}</div>
                            </div>
                            <div onClick={() => {
                              if (selectedPost._id === item._id) {
                                setSelectedPost("")
                              } else {
                                setSelectedPost(item);
                              }
                            }} className="cursor-pointer flex gap-1.5 self-start text-base">
                              <img
                                loading="lazy"
                                src={"/images/dashboard/celebrate/comment.png"}
                                className="object-contain shrink-0 w-6 aspect-square"
                              />
                              <div>{item?.chatCount}</div>
                            </div>
                          </div>
                          {selectedPost?._id == item?._id &&
                            <div className="w-full">
                              <div className="mt-5 text-sm font-medium leading-snug text-blue-600 max-md:ml-1.5">
                                Comments
                              </div>
                              <div style={{ scrollbarWidth: "none" }} className="overflow-x-hidden flex-wrap overflow-y-auto max-h-44 lg:max-h-64"                              >
                                {comments.map((item, index) => (
                                  <div key={index} className="mb-4"> {/* Margin for separation */}
                                    <div className="flex gap-4 mt-2.5 leading-loose text-black max-md:ml-1.5">
                                      <img
                                        loading="lazy"
                                        src={item.img}
                                        alt="Commenter"
                                        className="object-contain shrink-0 aspect-[1.02] rounded-full w-10 h-10"
                                      />
                                      <div className="flex flex-col my-auto">
                                        <div className="text-sm font-medium">{"User Name"}</div>
                                        <div className="self-start text-xs">{moment(item.createdAt).fromNow()}</div>
                                      </div>
                                    </div>
                                    <div className="self-stretch mt-1.5 mr-4 ml-3 text-sm leading-6 text-black max-md:mr-2.5 max-md:max-w-full">
                                      {item.description}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="flex flex-col gap-4 mt-4 w-full ">
                                <div className="flex justify-between self-stretch px-2 max-md:px-1 py-4 rounded-3xl bg-neutral-100 max-md:max-w-full">
                                  <form
                                    onSubmit={handleSubmit}
                                    className="flex items-center gap-2 w-full max-md:text-sm text-black"
                                  >
                                    <button
                                      type="button"
                                      onClick={() => setShowEmojis(!showEmojis)}
                                      className="flex items-center justify-center aspect-square w-6"
                                    >
                                      <img
                                        loading="lazy"
                                        src="/images/dashboard/emoji.png"
                                        className="aspect-square w-6"
                                      />
                                    </button>

                                    <input
                                      type="text"
                                      value={inputComment}
                                      onChange={handleInputChange}
                                      placeholder="Type here...."
                                      className="flex-1 outline-none border-none bg-neutral-100 pl-0.5 py-1 rounded"
                                    />

                                    <button type="submit" className="flex items-center justify-center aspect-square w-8">
                                      <img
                                        loading="lazy"
                                        src="/images/dashboard/send.png"
                                        className="aspect-square w-6"
                                      />
                                    </button>
                                  </form>
                                </div>
                              </div>
                              {showEmojis && (
                                <div className="flex gap-2 mt-2 mb-4">
                                  {emojis.map((emoji, index) => (
                                    <button
                                      key={index}
                                      type="button"
                                      onClick={() => handleEmojiClick(emoji)}
                                      className="text-xl"
                                    >
                                      {emoji}
                                    </button>
                                  ))}
                                </div>
                              )}
                            </div>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {allPosts.length >= 10 &&
        <div className=" flex justify-center items-center mt-3">
          <p onClick={() => setPage(page + 1)} className="border py-1 px-3 rounded-xl hover:border-blue-600 hover:text-blue-600  cursor-pointer">Load More...</p>
        </div>
      }
    </div>
  );
}
export default Celebrate;

