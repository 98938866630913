import React, { useState } from 'react'
import { useSelector } from "react-redux";
const RatingModal = ({ onCancel }) => {
    const [rating, setRating] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);
    const [feedback, setFeedback] = useState("");

    var count = 5;
    const handleClick = (index) => {
        setRating(index + 1);
    };

    const handleMouseOver = (index) => {
        setHoverValue(index + 1);
    };

    const handleMouseLeave = () => {
        setHoverValue(undefined);
    };

    const handleSubmit = () => {

    }

    return (
        <>
            <div className="justify-center items-center  flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-3xl shadow-lg mx-5 relative flex flex-col bg-white outline-none focus:outline-none max-w-[500px]">
                        <div className="flex overflow-hidden flex-col rounded-3xl ">
                            <div className="flex flex-col relative items-center w-full max-md:max-w-full">
                                <img
                                    loading="lazy"
                                    src="/images/dashboard/rate.png"
                                    className=""
                                />
                                <h1
                                    onClick={onCancel}
                                    className="my-auto  absolute top-3 right-3 w-9 h-9 flex justify-center items-center text-blue-600 bg-white rounded-xl font-semibold text-lg cursor-pointer"
                                >
                                    &#10005;
                                </h1>

                                <div className=" mt-3 text-2xl font-bebas max-md:text-center tracking-wider leading-snug text-slate-950 max-md:max-w-full max-md:text-xl">
                                    How Would you rate our App EXPERIENCE?
                                </div>
                                <div>
                                    {[...Array(count)].map((_, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                cursor: "pointer",
                                                color:
                                                    (hoverValue || rating) > index ? "gold" : "gray",
                                            }}
                                            onClick={() => handleClick(index)}
                                            onMouseOver={() => handleMouseOver(index)}
                                            onMouseLeave={handleMouseLeave}
                                            className="text-[60px] max-md:text-[40px]"
                                        >
                                            &#9733;
                                        </span>
                                    ))}
                                </div>
                                <input
                                    type="text"
                                    className="self-stretch border-none outline-none p-3 mx-7 max-md:mx-2 mt-6 max-md:mt-4 text-base max-md:text-[13px] rounded-xl bg-zinc-300 bg-opacity-40 text-zinc-500 max-md:max-w-full"
                                    placeholder=" Say something more about this app"
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                />
                                <button
                                    onClick={handleSubmit}
                                    className="py-4 mt-7 w-[60%] mb-4 max-w-full text-base text-center text-white whitespace-nowrap rounded-xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default RatingModal