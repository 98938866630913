import React, { useState } from "react";
import { FaArrowLeft, FaSearch, FaCheck } from "react-icons/fa";
import { SHOWCASEDATA } from "./showcaseData";

const AddShowcase = ({ setAddShowcase, onSave }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // Filter items based on the search query
  const filteredItems = SHOWCASEDATA.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleItemClick = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSave = () => {
    if (onSave) {
      onSave(selectedItems);
    }
    setAddShowcase(false); // Close the component after saving
  };
  return (
    <div className="flex flex-col px-3 py-5 max-md:px-2 w-full rounded-3xl bg-zinc-50 max-md:max-w-full">
      <div className="flex flex-col p-6 max-md:p-3 items-center w-full bg-white rounded-xl shadow-2xl max-md:max-w-full">
        <div className="flex gap-3 justify-between items-start self-start max-w-full">
          <div
            onClick={() => setAddShowcase(false)}
            style={{ boxShadow: " 0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
            className="bg-white rounded-full p-3 max-md:p-2 cursor-pointer"
          >
            <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
          </div>
          <div className="self-start mt-2 text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
            SHOWCASE
          </div>
        </div>
        <div className="md:w-[80%] max-md:w-full mt-10 max-md:mt-5 mx-auto">
          <div className="p-4">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="relative mb-4"
            >
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border px-4 py-3 rounded-xl w-full border-none outline-none"
              />
              <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </div>

            <div
              style={{ scrollbarWidth: "none" }}
              className=" flex flex-col gap-3 mt-10 max-md:mt-5 h-[400px]  overflow-auto p-3"
            >
              {filteredItems.length === 0 ? (
                <li>No items found.</li>
              ) : (
                filteredItems.map((item) => (
                  <div
                    onClick={() => handleItemClick(item.id)}
                    style={{
                      boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.12)",
                    }}
                    className={`relative flex flex-wrap gap-3 p-2 max-w-full text-sm bg-white rounded-2xl w-full max-md:pr-5 ${
                      selectedItems.includes(item.id)
                        ? "border-blue-500 border-[1px]"
                        : ""
                    }`}
                  >
                    <img
                      loading="lazy"
                      src={item.image}
                      className="object-contain shrink-0 w-20 rounded-2xl aspect-square"
                    />
                    <div className="flex flex-col grow shrink-0 self-start mt-2 basis-0 w-fit max-md:max-w-full">
                      <div className="tracking-normal text-black max-md:max-w-full">
                        {item.desc}
                      </div>
                      <div className="flex gap-1.5 self-start mt-2 font-medium text-purple-600">
                        <img
                          loading="lazy"
                          src={item.profilePic}
                          className="object-contain shrink-0 aspect-square rounded-full overflow-hidden w-[25px]"
                        />
                        <div className="my-auto">{item.name}</div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <button
          onClick={handleSave}
          className="p-4 mt-10 mb-5 max-w-full w-[70%] text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddShowcase;
