import { GoHome, GoHomeFill } from "react-icons/go";
import { FaBars } from "react-icons/fa"; // Import the icon for the toggle button
import { RiBookLine } from "react-icons/ri";
import { FaPen, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { HiAcademicCap } from "react-icons/hi2";
import { FaHandsClapping } from "react-icons/fa6";
import { PiHandsClapping, PiMedalMilitaryFill, PiPathLight } from "react-icons/pi";
import { BsPersonVcard } from "react-icons/bs";
import { BsPersonVcardFill } from "react-icons/bs";
import { MdOutlineBusinessCenter } from "react-icons/md";
export const IconFinder = ({ icon = "", size = 20, color = "", style = {}, className = "" }) => {
    return (<>
        {icon == "Home" ? <GoHome size={size} color={color} style={style} className={className} /> : null}
        {icon == "HomeFill" ? <GoHomeFill size={size} color={color} style={style} className={className} /> : null}
        {icon == "activePen" ? <FaPen size={size} color={color} style={style} className={className} /> : null}
        {icon == "pen" ? <GoPencil size={size} color={color} style={style} className={className} /> : null}
        {icon == "journey" ? <HiOutlineAcademicCap size={size} color={color} style={style} className={className} /> : null}
        {icon == "activeJourney" ? <HiAcademicCap size={size} color={color} style={style} className={className} /> : null}
        {icon == "celebrate" ? <PiHandsClapping size={size} color={color} style={style} className={className} /> : null}
        {icon == "activeCelebrate" ? <FaHandsClapping size={size} color={color} style={style} className={className} /> : null}
        {icon == "mentors" ? <BsPersonVcard size={size} color={color} style={style} className={className} /> : null}
        {icon == "activeMentors" ? <BsPersonVcardFill size={size} color={color} style={style} className={className} /> : null}
        {icon == "down" ? <FaChevronDown size={size} color={color} style={style} className={className} /> : null}
        {icon == "up" ? <FaChevronUp size={size} color={color} style={style} className={className} /> : null}
        {icon == "book" ? <RiBookLine size={size} color={color} style={style} className={className} /> : null}
        {icon == "bag" ? <MdOutlineBusinessCenter size={size} color={color} style={style} className={className} /> : null}
        {icon == "madel" ? <PiMedalMilitaryFill size={size} color={color} style={style} className={className} /> : null}
        {icon == "path" ? <PiPathLight size={size} color={color} style={style} className={className} /> : null}
    </>
    )
}