import * as React from "react";
import HeaderDash from "./components/HeaderDash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function JourneyMap({setActiveTab}) {
  const slide = React.useRef(null);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <main className="p-4">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-lg:flex-col">
          <div className="flex flex-col w-[63%] max-lg:w-full">
            <div className="flex flex-col grow max-md:mt-5 max-md:max-w-full">
              <div className="flex flex-col pl-6 pr-7 py-5 w-full text-lg leading-snug bg-white rounded-xl shadow-[0px_4px_44px_rgba(0,0,0,0.06)] text-indigo-950 max-md:pl-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-2.5">
                  <div className="flex flex-col grow shrink-0 items-start basis-0 w-fit max-md:max-w-full">
                    <div className="text-3xl max-md:text-2xl font-bebas tracking-[1px] text-green-600">
                      CHECK OUT YOUR TRAITS
                    </div>
                    <div className="flex gap-2.5 mt-6">
                      <img
                        loading="lazy"
                        src="/images/dashboard/journey/socials.png"
                        className="object-contain shrink-0 self-start w-6 aspect-[1.09]"
                      />
                      <div className="text-base">Social (28)</div>
                    </div>
                    <div className="self-stretch mt-1.5 text-[15px] leading-6 text-neutral-400 max-md:max-w-full">
                      Adapters are unique and they have the ability of being
                      able to adapt to the needs of environment. The shaded
                      circles and scores below indicate your strength level as a
                      percentage of the population with the corresponding
                      descriptor in the same color.
                    </div>
                    <div className="flex gap-2.5 mt-6">
                      <img
                        loading="lazy"
                        src="/images/dashboard/journey/artistic.png"
                        className="object-contain shrink-0 self-start w-6 aspect-[1.09]"
                      />
                      <div className="text-base">Artistic (28)</div>
                    </div>
                    <div className="self-stretch mt-1.5 text-[15px] leading-6 text-neutral-400 max-md:max-w-full">
                      Adapters are unique and they have the ability of being
                      able to adapt to the needs of environment. The shaded
                      circles and scores below indicate your strength level as a
                      percentage of the population with the corresponding
                      descriptor in the same color.
                    </div>
                  </div>
                </div>
                <div className="flex gap-2.5 self-start mt-8">
                  <img
                    loading="lazy"
                    src="/images/dashboard/journey/investigative.png"
                    className="object-contain shrink-0 self-start w-6 aspect-[1.09]"
                  />
                  <div className="text-base">Investigative (28)</div>
                </div>
                <div className="mt-1.5 text-[15px] leading-6 text-neutral-400  max-md:max-w-full">
                  Adapters are unique and they have the ability of being able to
                  adapt to the needs of environment. The shaded circles and
                  scores below indicate your strength level as a percentage of
                  the population with the
                </div>
              </div>
              <div className="flex flex-col px-8 py-7 mt-5 w-full bg-white rounded-xl shadow-2xl max-md:px-5 max-md:max-w-full">
                <div className="flex flex-wrap gap-5 justify-between text-2xl text-slate-950 max-md:mr-2.5 max-md:max-w-full">
                  <div className="max-md:max-w-full font-bebas tracking-[0.5px]">
                    Recommended Occupations based on interest
                  </div>
                  <div className="flex gap-2">
                    <img
                      onClick={() => slide.current.slickPrev()}
                      loading="lazy"
                      src="/images/dashboard/lt1.png"
                      className="w-8"
                    />
                    <img
                      onClick={() => slide.current.slickNext()}
                      loading="lazy"
                      src="/images/dashboard/gt1.png"
                      className="w-8"
                    />
                  </div>
                </div>
                <div className=" mt-7">
                  <Slider ref={slide} {...settings}>
                    {sliders.map((item) => {
                      return (
                        <div className="flex flex-col px-2 max-lg:w-full">
                          <div className="flex flex-col p-2 w-full min-h-[250px] bg-white rounded-2xl border border-solid border-zinc-100 max-md:mt-5">
                            <div className="relative w-full">
                              <img
                                loading="lazy"
                                src={item.img}
                                className="object-cover rounded-2xl aspect-[1.88] w-44 max-lg:w-full"
                              />
                              <img
                                loading="lazy"
                                src="/images/dashboard/journey/std.png"
                                className="absolute bottom-0 right-0 translate-y-3 object-contain rounded-2xl aspect-[1.88] w-14 max-lg:w-20"
                              />
                            </div>
                            <div className="self-start mt-3 text-[15px] font-medium text-neutral-700">
                              {item.name}
                            </div>
                            <div className="text-xs  text-black ">
                            {item.desc}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col  w-[37%] max-lg:w-full">
            <div className="flex flex-col p-3 mx-auto w-full bg-white rounded-xl shadow-[0px_4px_19px_rgba(0,0,0,0.05)] max-md:px-5 max-md:mt-5 max-md:max-w-full">
              <div className="self-start text-3xl mb-5 font-bebas tracking-[1px] text-slate-950">
                Institutions
              </div>
              {institute.map((item) => {
                return (
                  <div className="p-1 mt-3 bg-white rounded-xl border border-gray-200 border-solid max-md:pr-5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col">
                      <div className="flex flex-col w-[22%] max-md:ml-0 max-md:w-full">
                        <img
                          loading="lazy"
                          src={item.img}
                          className="object-contain my-auto rounded-xl aspect-square max-md:mt-5"
                        />
                      </div>
                      <div className="flex flex-col  w-[78%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col self-stretch my-auto max-md:mt-7">
                          <div className="self-start text-[15px] font-medium tracking-tight leading-none text-neutral-700">
                            {item.name}
                          </div>
                          <div className="mt-1 text-[13px] leading-5 text-neutral-400">
                            {item.desc}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default JourneyMap;

const institute = [
  {
    img: "/images/dashboard/journey/institute1.png",
    name: "InterCoast Colleges-  Riverside",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/journey/institute2.png",
    name: "The Fab School",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/journey/institute3.png",
    name: "American Institute of Massage Therapy",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/journey/institute4.png",
    name: "ATA College",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/journey/institute5.png",
    name: "Pomona College",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
  {
    img: "/images/dashboard/journey/institute6.png",
    name: "Advance Beauty College",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
];
const sliders = [
  {
    img: "/images/dashboard/journey/occupations3.png",
    name: "Art, Drama & Music",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum.  ",
  },
  {
    img: "/images/dashboard/journey/occupations2.png",
    name: "Art Therapists",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  }, {
    img: "/images/dashboard/journey/occupations1.png",
    name: "Childcare Workers",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  }, {
    img: "/images/dashboard/journey/occupations4.png",
    name: "Edu Teacher",
    desc: "Lorem ipsum dolor sit amet consectetur. Mauris vestibulum id ut diam sed tellus urna ipsum. ",
  },
];
