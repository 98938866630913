import React, { useState } from "react";
import HeaderDash from "../components/HeaderDash";

function Settings({ setActiveTab }) {
  const [all, setAll] = useState(false);
  const [isOn2, setIsOn2] = useState(false);
  const [isOn3, setIsOn3] = useState(false);
  const [isOn4, setIsOn4] = useState(false);
  const [isOn5, setIsOn5] = useState(false);

  const handleToggle1 = () => {
    const newAllState = !all;
    setAll(newAllState);
    setIsOn2(newAllState);
    setIsOn3(newAllState);
    setIsOn4(newAllState);
    setIsOn5(newAllState);
  };

  const handleToggle2 = () => {
    setIsOn2((prevState) => {
      const newState = !prevState;
      if (!newState) setAll(false);
      return newState;
    });
  };

  const handleToggle3 = () => {
    setIsOn3((prevState) => {
      const newState = !prevState;
      if (!newState) setAll(false);
      return newState;
    });
  };

  const handleToggle4 = () => {
    setIsOn4((prevState) => {
      const newState = !prevState;
      if (!newState) setAll(false);
      return newState;
    });
  };

  const handleToggle5 = () => {
    setIsOn5((prevState) => {
      const newState = !prevState;
      if (!newState) setAll(false);
      return newState;
    });
  };
  return (
    <div className="p-4 min-w-[320px] ">
      <HeaderDash setActiveTab={setActiveTab} />
      <div className="flex flex-col self-center p-6 max-md:p-4 mt-6 md:w-[80%] mx-auto max-w-full bg-white rounded-3xl shadow-2xl max-md:mr-3">
        <div className="self-start text-2xl font-bebas tracking-[1px] text-green-600">
          NOTIFICATION SETTINGS
        </div>
        <div className="flex  gap-5 justify-between mt-8 max-md:max-w-full">
          <div className="flex flex-col max-md:max-w-full">
            <div className="self-start text-base font-medium text-black">
              All Notifications
            </div>
            <div className="text-sm text-neutral-400 max-md:max-w-full">
              Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis
              diam lectus sed.{" "}
            </div>
          </div>
          <div className="space-y-4">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={all}
                onChange={handleToggle1}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="shrink-0 mt-4 border border-solid border-neutral-200 max-md:max-w-full" />
        <div className="flex justify-between w-full mt-6 flex-wrap gap-10 rounded-none">
          <div className="text-base text-neutral-400">Reminders</div>
          <div className="space-y-4">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isOn2}
                onChange={handleToggle2}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="flex justify-between w-full mt-6 flex-wrap gap-10 rounded-none">
          <div className="text-base  text-neutral-400">Alerts</div>
          <div className="space-y-4">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isOn3}
                onChange={handleToggle3}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="flex justify-between w-full mt-6 flex-wrap gap-10 rounded-none">
          <div className="text-base  text-neutral-400">Alerts</div>
          <div className="space-y-4">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isOn4}
                onChange={handleToggle4}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="flex justify-between w-full mt-6 flex-wrap gap-10 rounded-none">
          <div className="text-base text-neutral-400">Alerts</div>
          <div className="space-y-4">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isOn5}
                onChange={handleToggle5}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-5 peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
      </div>

      <div className="flex flex-col self-center p-6 max-md:p-4 mt-6 md:w-[80%] mx-auto max-w-full bg-white rounded-3xl shadow-2xl max-md:mr-3 ">
        <div className="flex flex-wrap gap-5 justify-between w-full max-md:max-w-full">
          <div className="text-2xl tracking-[1px] font-bebas text-green-600">
            Help & support
          </div>
          <div className="flex flex-wrap gap-x-9 gap-y-6 self-start text-base text-center">
            <div className="flex gap-1.5">
              <img
                loading="lazy"
                src="/images/dashboard/profile/call.png"
                className="object-contain shrink-0 aspect-square w-[22px] max-md:w-[19px]"
              />
              <div className="my-auto text-blue-600 max-md:text-sm">
                +1 8976546789
              </div>
            </div>
            <div className="flex flex-auto gap-2.5 my-auto whitespace-nowrap">
              <img
                loading="lazy"
                src="/images/dashboard/profile/mail.png"
                className="object-contain shrink-0 my-auto aspect-square w-[22px] max-md:w-[19px]"
              />
              <div className="text-blue-600 max-md:text-sm">
                company-email@gmail.com
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          {HelpSupport.map((item) => {
            return (
              <div>
                <div className="flex self-start gap-2 mt-5 text-sm font-medium text-blue-600">
                  <img
                    loading="lazy"
                    src={item.img}
                    className="object-contain shrink-0 self-start aspect-[4/4] w-[20px]"
                  />
                  <div className="">{item.heading}</div>
                </div>
                <div className="mr-4 text-sm text-neutral-400 max-md:mr-2.5 max-md:max-w-full">
                  {item.desc}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Settings;
const HelpSupport = [
  {
    img: "/images/dashboard/profile/logo.png",
    heading: "FAQ Question 1",
    desc: "Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. ",
  },
  {
    img: "/images/dashboard/profile/logo.png",
    heading: "FAQ Question 2",
    desc: "Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. ",
  },
  {
    img: "/images/dashboard/profile/logo.png",
    heading: "FAQ Question 3",
    desc: "Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. ",
  },
  {
    img: "/images/dashboard/profile/logo.png",
    heading: "FAQ Question 4",
    desc: "Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. ",
  },
  {
    img: "/images/dashboard/profile/logo.png",
    heading: "FAQ Question 5",
    desc: "Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. Lorem ipsum dolor sit amet consectetur. Faucibus purus facilisis diam lectus sed. ",
  },
];
