import React from 'react'
import { useAuth } from '../../../authContext/AuthContext';

const LogoutModal = ({ onCancel }) => {
    const { logout } = useAuth();
    const handleLogout = () => {
        logout();
        onCancel();
    }
    return (
        <>
            <div className="justify-center items-center  flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg mx-5 relative flex flex-col bg-white outline-none focus:outline-none">
                        <div className="py-5 max-md:px-5">
                            <div>
                                <div className="z-10 self-center uppercase flex justify-center text-4xl max-md:text-2xl font-bebas tracking-[1px] mt-4 text-green-600">
                                    Logging Out?
                                </div>
                                <div className="mx-auto text-center  w-[70%] mt-3 text-base tracking-tight self-center text-neutral-800">
                                    Are you sure you want to log outfrom our application?
                                </div>
                            </div>
                            <div className="flex justify-center gap-3 mt-5 w-full text-sm">
                                <button
                                    onClick={onCancel}
                                    className="gap-2.5 self-stretch px-7 py-4 text-blue-600 whitespace-nowrap rounded-2xl border border-blue-600 border-solid min-h-[55px] max-md:px-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleLogout}
                                    className="gap-2.5 self-stretch px-7 py-4 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] min-h-[55px] max-md:px-5"
                                >
                                    Log Out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default LogoutModal