import React, { useState } from "react";
import HeaderDash from "./components/HeaderDash";
import Test from "./components/Test";

const Survey = ({ setActiveTab }) => {
  const [isTestStarted, setIsTestStarted] = useState(false);
  return (
    <main className="p-4">
      <HeaderDash setActiveTab={setActiveTab} />
      {isTestStarted ? (
        <Test setActiveTab={setActiveTab} />
      ) : (
        <div className="flex flex-col p-4 h-full mt-5 w-full bg-white rounded-xl shadow-2xl max-md:pl-5 max-md:max-w-full">
          <img
            src="/images/dashboard/survey/bg.png"
            alt="not found"
            className=" rounded-xl"
          />

          <div className="z-10 mt-8 mr-6 mb-0 max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-[76%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col w-full text-base text-zinc-600 max-md:mt-10 max-md:max-w-full">
                  <div className="self-start text-3xl tracking-[1px] font-bebas text-green-600 ">
                    INSTRUCTIONS
                  </div>
                  <div className="mt-3 max-md:mr-2.5 max-md:max-w-full">
                    Each question has three statements. You’ll be asked to
                    choose two of the statements for each question – how you
                    most naturally operate and how you least naturally operate.
                  </div>
                  <div className="mt-3 max-md:mr-2.5 max-md:max-w-full">
                    Base your choices on how you act, make decisions, and
                    interact. You might find some questions more difficult than
                    others, go with your intuition and don’t dwell on it
                  </div>
                  <div className="mt-3 max-md:max-w-full">
                    The questions focus on “who you are” and not on “who you
                    want to be.” A scientifically validated calculation will
                    translate your responses into results on how people like you
                    approach work, make decisions, and build relationships.
                  </div>
                  <div className="self-start mt-3 max-md:max-w-full">
                    You’ll see your dashboard at the end that will reveal your
                    workplace talents.
                  </div>
                  <button
                    onClick={() => setIsTestStarted(true)}
                    className="gap-2.5 self-center px-24 max-md:px-16 mt-10 py-4 text-white rounded-2xl bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] min-h-[55px]"
                  >
                    Start Test
                  </button>
                </div>
              </div>
              <div className="flex flex-col justify-end ml-5 w-[24%] max-md:ml-0 max-md:w-full max-md:hidden">
                <img
                  src="/images/dashboard/survey/student.png"
                  alt="not found"
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Survey;
