import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Cookies from 'js-cookie';
import { useDispatch } from "react-redux";
import { userAction } from "../redux/common";
// import { LogOutApi } from "../Axios/Api";

const AuthContexts = createContext();

const AuthContext = ({ children }) => {
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [school, setSchool] = useState(null);
    const [accountType, setAccountType] = useState(1);
    const [loading, setLoading] = useState(true);
    const [logged, setLogged] = useState(false);

    const handleSetToken = (newToken, aType) => {
        if (newToken == null) {
            Cookies.remove("Token");
            Cookies.remove("Atype");
        } else {
            Cookies.set('Token', newToken, {
                expires: 7,
                // secure: true ,
                sameSite: 'Lax'
            });
            Cookies.set('Atype', aType, {
                expires: 7,
                // secure: true ,
                sameSite: 'Lax'
            });
            setAccountType(aType);
        }
    };

    const handleSetUser = (userData) => {
        setUser(userData);
        dispatch(userAction(userData));
        // console.log(userData);
        if (userData == null) {
            localStorage.removeItem("user");
        } else {
            localStorage.setItem("user", JSON.stringify(userData));
        }
    };

    const login = (userData, token, aType) => {
        handleSetUser(userData);
        handleSetToken(token, aType);
        setLogged(true);
        setLoading(false);
    };

    const handleSetSchool = (schoolData) => {
        setSchool(schoolData);
        if (schoolData == null) {
            localStorage.removeItem("school");
        } else {
            localStorage.setItem("school", JSON.stringify(schoolData));
        }
    }
    const loginSchool = (schoolData, token, aType) => {
        console.log("school login come");
        console.log("school :", schoolData);
        handleSetSchool(schoolData);
        handleSetToken(token, aType);
        setLogged(true);
        setLoading(false);
    }

    const logout = () => {
        console.log("user data removed");
        handleSetToken(null);
        handleSetUser(null);
        setLogged(false);
        setLoading(false);
        // LogOutApi()
        // .then((response)=>{
        //     console.log('Logout response:', response);
        // })
        // .catch((error)=>{
        //     console.log('Logout error:', error.response);
        // });
    };

    const updateUserData = (key, value) => {
        setUser(prevUser => {
            const updatedUser = { ...prevUser, [key]: value };
            localStorage.setItem("user", JSON.stringify(updatedUser));
            return updatedUser;
        });
    };

    useEffect(() => {
        const tkn = Cookies.get("Token");
        const loginType = Cookies.get("Atype")
        const usr = localStorage.getItem("user");
        const sch = localStorage.getItem("school");
        // console.log(tkn)
        if (tkn && tkn !== "null" && usr && usr !== "null") {
            handleSetToken(tkn, loginType);
            handleSetUser(JSON.parse(usr));
            setLogged(true);
        } else if (tkn && tkn !== "null" && sch && sch !== "null") {
            // console.log("come ghdgf");
            handleSetToken(tkn, loginType);
            handleSetSchool(JSON.parse(sch));
            setLogged(true);
        } else {
            handleSetToken(null);
            handleSetUser(null);
            setLogged(false);
        }
        setLoading(false);
    }, []);

    const contextValue = useMemo(() => ({
        loading,
        logged,
        token: Cookies.get("Token"),
        user,
        school,
        accountType,
        setLoading,
        login,
        loginSchool,
        logout,
        updateUserData,
    }), [loading, logged, user, accountType, school]);

    return (
        <AuthContexts.Provider value={contextValue}>
            {children}
        </AuthContexts.Provider>
    );
};

export const useAuth = () => useContext(AuthContexts);

export default AuthContext
