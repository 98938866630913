import React from 'react'
import LogoutModal from './auth/components/allModals/LogoutModal'
import CreateCelebration from './auth/components/allModals/CreateCelebration'
import DeleteModal from './auth/components/allModals/DeleteModal'

const AllModals = ({ children }) => {
    return (
        <div>
            {/* <LogoutModal /> */}
            <DeleteModal />
            <CreateCelebration />
            {children}
        </div>
    )
}

export default AllModals