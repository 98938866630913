import React from 'react'
import { imageLink, truncateText } from '../auth/components/commonFunction'
import { FaUser } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const SingleMicrocredentials = ({ onClick, item }) => {
    return (
        <div
            onClick={onClick}
            className="flex flex-wrap gap-5 mt-3  border-[1px] pl-1 pr-2 cursor-pointer rounded-lg"
        >
            <div className="flex justify-between w-full max-md:flex-wrap gap-x-16 gap-y-5 items-center bg-white max-md:max-w-full">
                <div className="flex gap-4 text-black">
                    <img
                        loading="lazy"
                        src={item?.courseImage.length > 0 ? imageLink(item?.courseImage[0]) : "/images/dashboard/microcredential1.png"}
                        className=" h-[55px] md:my-auto max-md:mt-1 w-[55px]"
                    />
                    <div className="flex flex-col max-md:max-w-full">
                        <div className="self-start text-base font-medium">
                            {item?.courseName}
                        </div>
                        <div className="text-sm max-md:max-w-full">
                            {truncateText(item?.description, 15)}
                        </div>
                    </div>
                </div>
                <div className="flex gap-3 whitespace-nowrap  my-auto text-sm text-neutral-700">
                    <div className="text-lg text-blue-500">
                        {" "}
                        <FaUser />
                    </div>
                    <div>{item.totalNumberOfLearners}</div>
                </div>
                <div className=""><FaChevronRight /></div>
            </div>
        </div>
    )
}

export default SingleMicrocredentials