import React, { useState, useEffect } from "react";
import { FaUser, FaSchool } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Congratulation from "./Congratulation";


const ProceedPayment = () => {
  const [showCongratulationPage, setShowCongratulationPage] = useState(false);

  const location = useLocation();
  const { selectedPlan, selectedPaymentOption, subscription } =
    location.state || {};
  // const buttonText = data?.button || 'Submit';
  // Log the data to the console for debugging
  console.log(selectedPlan);
  console.log("Data:", selectedPaymentOption);
  console.log("subscription:", subscription);
  const navigate = useNavigate();

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({
      cardNumber,
      expiryDate,
      cvc,
      name,
    });
  };

  const gotoCongratulation = () => {
    navigate("/congratulation", {
      state: {subscription ,selectedPlan },
    });
  };


  return (
    <div>
      {showCongratulationPage ? (
        <Congratulation />
      ) : (
        <div
          style={{
            backgroundImage: "url(/images/subscription/subscription-bg.png)",
          }}
          className="relative min-w-[320px] min-h-[100vh] bg-fixed bg-top bg-cover bg-no-repeat"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#1D68FF] to-[#9A52DE97] opacity-75"></div>
          <div className="relative flex flex-col items-center justify-center min-h-full py-8 sm:py-16 px-4">
            <div className="flex flex-col md:flex-row justify-between items-end ">
              <div className="text-white py-5">
                <h1 className="text-5xl font-bebas max-md:text-4xl tracking-[2.55px] ">
                  Proceed to pay...
                </h1>
                <p className="text-xs md:text-sm mt-1">
                  Lorem ipsum dolor sit amet consectetur. Tellus ac sociis
                  lobortis at fermentum.
                </p>
              </div>
              <img
                src="/images/subscription/student.png"
                alt="not found"
                className="w-3/4 md:w-1/3"
              />
            </div>
            <div className="bg-white shadow-lg rounded-lg py-10 overflow-hidden w-full max-w-5xl flex flex-col md:flex-row z-10">
              <div className="w-full md:w-[37%] px-10 pt-5 my-auto">
                <div className="my-auto">
                  <div
                    style={{
                      boxShadow: "0px 0px 31px 0px rgba(0, 0, 0, 0.07)",
                    }}
                    className={`p-4 border-2 flex justify-between rounded-3xl mb-4 cursor-pointer ${
                      selectedPlan === "Individual" ? "" : "hidden"
                    }`}
                  >
                    <div className="flex flex-col justify-between text-[#919191]">
                      <h2 className="text-lg md:text-xl font-bold text-[#009A39]">
                        $49.00
                      </h2>
                      <p className="text-[#919191]">Yearly</p>
                      <p className="font-bold text-[#090D20] mt-2">
                        Individual
                      </p>
                      <p className="text-sm">This is an individual plan</p>
                    </div>
                    <FaUser className="text-[#1D68FF] bg-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
                  </div>
                  <div
                    style={{
                      boxShadow: "0px 0px 31px 0px rgba(0, 0, 0, 0.07)",
                    }}
                    className={`p-4 border-2 flex justify-between rounded-3xl mb-4 cursor-pointer ${
                      selectedPlan === "Schools" ? "" : "hidden"
                    }`}
                  >
                    <div className="flex flex-col justify-between text-[#919191]">
                      <h2 className="text-lg md:text-xl font-bold text-[#009A39]">
                        $17.00
                      </h2>
                      <p className="text-[#919191]">Yearly</p>
                      <p className="font-bold text-[#090D20] mt-2">Schools</p>
                      <p className="text-sm">This is per student plan</p>
                    </div>
                    <FaSchool className="text-[#1D68FF] bg-[#1D68FF] bg-opacity-20 h-12 w-12 p-3 rounded-full" />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-[63%] bg-white px-10 max-md:mt-10 border-l-[1px]">
                <div className="">
                  <h2 className="text-2xl text-[#009A39] font-bebas tracking-[2px] mb-7">
                    {selectedPaymentOption}
                  </h2>
                  <form onSubmit={handleSubmit} className="text-[15px]">
                    <div
                      style={{
                        boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)",
                      }}
                      className="mb-4 px-4 py-1 rounded-lg"
                    >
                      <label className="block text-gray-700 font-medium  mb-1">
                        Card Number
                        <input
                          type="text"
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                          placeholder="1234 5678 9012 3456"
                          className="w-full border border-gray-300 rounded mt-1 outline-none border-none"
                          maxLength="19"
                        />
                      </label>
                    </div>
                    <div className="mb-4 md:flex md:gap-5">
                      <label
                        style={{
                          boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)",
                        }}
                        className="block text-gray-700 font-medium mb-1 px-4 rounded-lg py-1"
                      >
                        Expiration Date
                        <input
                          type="text"
                          value={expiryDate}
                          onChange={(e) => setExpiryDate(e.target.value)}
                          placeholder="MM/YY"
                          className="w-full border border-gray-300 rounded mt-1 outline-none border-none"
                          maxLength="5"
                        />
                      </label>
                      <div className="max-md:my-4">
                        <label
                          style={{
                            boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)",
                          }}
                          className="block text-gray-700 font-medium mb-1 px-4 rounded-lg py-1"
                        >
                          CVV:
                          <input
                            type="text"
                            value={cvc}
                            onChange={(e) => setCvc(e.target.value)}
                            placeholder="123"
                            className="w-full border border-gray-300 rounded mt-1 outline-none border-none"
                            maxLength="4"
                          />
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)",
                      }}
                      className="block text-gray-700 font-medium mb-1 px-4 rounded-lg py-1"
                    >
                      <label className="block text-gray-700 font-medium mb-1">
                        Card Holder Name
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="John Doe"
                          className="w-full border border-gray-300 rounded mt-1 outline-none border-none"
                        />
                      </label>
                    </div>
                    <div className="flex gap-2 my-auto mt-5 md:ml-1">
                      <input type="checkbox" className="w-5 h-5 mt-[2px]" />
                      <p className="">Save card for future checkouts</p>
                    </div>
                    <button
                    
                      onClick={() => gotoCongratulation()}
                      style={{
                        background:
                          "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                      }}
                      type="submit"
                      className="w-full py-3 rounded-xl mt-5 bg-blue-500 text-white hover:bg-blue-600"
                    >
                      {selectedPlan === "Schools"
                        ? "Pay $289.00"
                        : "Pay $49.00"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProceedPayment;
