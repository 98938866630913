import React, { useState } from "react";
import ReportProcessingPlan from "./ReportProcessingPlan"; // Adjust the path if needed
import { useDispatch } from "react-redux";
import { c_plan_update } from "../../redux/c_plans_slice";

const DiscoverInterest = ({ onFinish }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  const [showReportProcessingPlan, setShowReportProcessingPlan] =
    useState(false);

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null); // Reset selection when moving to the next question
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOption(null); // Reset selection when moving to the previous question
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleFinish = () => {
    setShowReportProcessingPlan(true);

    setTimeout(() => {
      setShowReportProcessingPlan(false);
      dispatch(c_plan_update(true));
      if (onFinish) onFinish(); // Notify parent to navigate to the Survey page
    }, 3000);
  };

  if (showReportProcessingPlan) {
    return <ReportProcessingPlan />;
  }

  // Calculate progress width
  const progressPercentage =
    ((currentQuestionIndex + 1) / questions.length) * 100;

  return (
    <div className="w-full mt-3 p-4">
      <div className="self-start text-2xl font-bebas tracking-widest text-green-600">
        JOURNEYS MAP
      </div>
      <div className="mt-2 text-base leading-6 text-gray-600 max-md:max-w-full">
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem. Sed ut perspiciatis unde omnis
        iste natus error sit voluptatem accusantium doloremque laudantium, totam
        rem aperiam, eaque ipsa quae ab illo inven
      </div>
      <div className="w-[95%] mx-auto mt-10">
        <h2 className="text-base font-semibold text-[#1D68FF]">
          Question {currentQuestionIndex + 1} of {questions.length}
        </h2>
        <div className="bg-[#F3F4FE] h-2 rounded-full mt-1">
          <div
            className="bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)] h-2 rounded-full"
            style={{
              width: `${progressPercentage}%`,
            }}
          ></div>
        </div>
      </div>
      <div className="mt-10">
        <h3 className="flex justify-center text-2xl font-semibold text-gray-800 mt-4 max-md:text-xl">
          {questions[currentQuestionIndex].question}
        </h3>
        <p className="flex justify-center text-gray-600 text-[15px] mt-1">
          {questions[currentQuestionIndex].description}
        </p>
      </div>

      <div className="flex flex-wrap w-full justify-center gap-5 mt-8">
        {questions[currentQuestionIndex].options.map((option, index) => (
          <div
            key={index}
            className={`flex flex-col items-center cursor-pointer 
              ${selectedOption === option ? "bg-orange-300" : ""} 
               flex items-center justify-center rounded-full max-w-40`}
            onClick={() => handleOptionClick(option)}
          >
            <img
              onClick={handleNext}
              src={option.img}
              alt={option.text}
              className="w-32 max-md:w-20"
            />
            <p className="mt-2 text-gray-700">{option.text}</p>
          </div>
        ))}
      </div>

      <div className="flex justify-center gap-3 mt-20">
        {currentQuestionIndex === questions.length - 1 ? (
          <button
            onClick={handleFinish}
            disabled={!selectedOption}
            className="w-56 mx-auto self-center text-white px-4 py-3 cursor-pointer rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
          >
            Finish
          </button>
        ) : (
          <>
            <button onClick={handleBack} disabled={currentQuestionIndex === 0}>
              <img
                src={
                  currentQuestionIndex === 0
                    ? "/images/dashboard/survey/lt.png"
                    : "/images/dashboard/survey/lt2.png"
                }
                alt="Previous"
                className="w-14 my-auto cursor-pointer"
              />
            </button>
            <button onClick={handleNext} disabled={!selectedOption}>
              <img
                src="/images/dashboard/survey/gt.png"
                alt="Next"
                className="w-14 my-auto cursor-pointer"
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default DiscoverInterest;
const questions = [
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Are you comfortable with public speaking?",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do you enjoy working in teams?",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
  {
    question: "Do volunteer work at a non-profit organization",
    description:
      "Assists others, provide others with hope, courage, or confidence",
    options: [
      { text: "Dislike", img: "/images/dashboard/career-planning/dislike.png" },
      { text: "Meh", img: "/images/dashboard/career-planning/meh.png" },
      { text: "Unsure", img: "/images/dashboard/career-planning/unsure.png" },
      { text: "Like It", img: "/images/dashboard/career-planning/like-it.png" },
      { text: "Love It", img: "/images/dashboard/career-planning/love-it.png" },
    ],
  },
];
