import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteAction } from '../../../redux/common';
import { ClearDeleteMemory } from '../commonFunction';

const DeleteModal = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const deleteMemory = useSelector(state => state.common.DeleteMemory);
    console.log(deleteMemory)
    useEffect(() => {
        if (deleteMemory.enable && deleteMemory.address && deleteMemory.id) {
            console.log("call");

            setIsOpen(true);
        }
    }, [deleteMemory]);

    const OkDelete = () => {
        setIsOpen(false);
        dispatch(DeleteAction({ ...deleteMemory, enable: false, answer: true }))
    }

    return (
        isOpen &&
        <div>
            <>
                <div className="justify-center items-center max-md:mx-8 flex overflow-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex flex-col rounded-none">
                                <div className="flex flex-col items-start pt-2 px-5 pb-5 md:w-[400px] w-full bg-white rounded-3xl max-md:p-5 max-md:max-w-full">
                                    <div className="flex justify-end w-full">
                                        <img
                                            onClick={ClearDeleteMemory}
                                            loading="lazy"
                                            src="/images/dashboard/celebrate/cross.png"
                                            className=" my-auto bg-gray-50 cursor-pointer  rounded-full aspect-square w-[35px]"
                                        />
                                    </div>
                                    <div className="flex justify-center w-full text-2xl font-bebas text-[#FF5271] tracking-[1px]">
                                        DELETE POST
                                    </div>

                                    <div className="mt-2 mx-auto text-[15px] leading-[25px] max-w-[300px] text-center text-neutral-400 ">
                                        Are you sure you want to delete post? This action cannot
                                        be reversed.
                                    </div>
                                    <button onClick={OkDelete} className=" mx-auto w-[80%] px-10 py-4 mt-7  text-sm text-center text-white whitespace-nowrap bg-[#FF1942] rounded-2xl ">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        </div>
    )
}

export default DeleteModal