import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const Congratulation = () => {
  const location = useLocation();
  const { subscription, selectedPlan } = location.state || {};

  const navigate = useNavigate();
  const goToDashBoard = () => {
    navigate("/admin-index", {});
    if (selectedPlan === "Schools") {
      navigate("/school-portal", {});
    } else {
      navigate("/admin-index", {});
    }
  };
  useEffect(() => {
    if (subscription === "individual") {
      // Set a timeout for 3 seconds
      const timer = setTimeout(() => {
        navigate("/admin-index", {
          state: 8,
        });
      }, 3000);
      

      // Cleanup the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
    if (subscription === "school") {
      // Set a timeout for 3 seconds
      const timer = setTimeout(() => {
        navigate("/school-portal", {
          state: 2,
        });
      }, 3000);
      

      // Cleanup the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [subscription]);
  return (
    <div
      style={{
        backgroundImage: "url(/images/subscription/subscription-bg.png)",
      }}
      className="relative min-w-[320px] min-h-[100vh] bg-fixed bg-top bg-cover bg-no-repeat"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-[#1D68FF] to-[#9A52DE97] opacity-75"></div>
      <div className="relative flex flex-col items-center justify-center min-h-full py-8 sm:py-16 px-4">
        <div className="bg-white relative shadow-lg rounded-lg py-10 overflow-hidden w-full max-w-5xl flex flex-col md:flex-row z-10">
          <div className="absolute bottom-10 -z-10 left-10 bg-[#009A39] h-[120px] w-[120px] blur-[80px] transform-[rotate(28.848deg)] flex-shrink-0"></div>
          <div className="absolute top-0 -z-10 left-10 bg-[#009A39] h-[120px] w-[120px] blur-[80px] transform-[rotate(28.848deg)] flex-shrink-0"></div>
          <div className="absolute top-0 -z-10 right-10 bg-[#9335D1] h-[120px] w-[120px] blur-[80px] transform-[rotate(28.848deg)] flex-shrink-0"></div>
          <div className="absolute bottom-10 -z-10 right-10 bg-[#FFBD59] h-[120px] w-[120px] blur-[80px] transform-[rotate(28.848deg)] flex-shrink-0"></div>
          <div className="flex justify-between max-md:flex-wrap gap-10 md:px-16 max-md:px-5 py-10 w-full">
            <div className="md:w-1/3 mx-auto max-md:w-full">
              <img
                src="/images/subscription/payment-success2.png"
                className=" mt-10 max-md:mx-auto max-md:w-[70%]"
              />
            </div>
            <div className="md:w-1/3 w-full max-md:px-5 md:mt-28 max-md:mt-10 my-auto">
              <div className="text-[#9335D0] font-bebas text-5xl flex justify-center max-md:px-10 max-md:text-4xl">
                CONGRATULATIONS
              </div>
              <div className="mt-5 flex justify-center">
                Your payment is successfully!
              </div>
              <div className="mt-1 text-center">
                Thank you for choosing our subscription plan. Start exploring
                our app now!
              </div>
              {subscription === "individual" || subscription === "school" ? (
                ""
              ) : (
                <button
                  onClick={() => goToDashBoard()}
                  style={{
                    background:
                      "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
                  }}
                  type="submit"
                  className="w-full p-3 rounded-xl mt-5 z-10 bg-blue-500 text-white hover:bg-blue-600"
                >
                  Go to dashboard
                </button>
              )}
            </div>

            <div className="1/3">
              <img
                src="/images/subscription/payment-success1.png"
                className="max-lg:hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
