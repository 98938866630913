import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Slick1 from "../common/Slick1";
import { useNavigate } from "react-router-dom";

function CreatePass() {
  const navigate = useNavigate();

  const [showPasswords, setShowPasswords] = useState({
    password1: false,
    password2: false,
  });
  const handleClickShowPassword = (field) => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // form handling
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    schoolName: "",
    schoolDist: "",
    createPass: "",
    confirmPass: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };
  const goToIndividualSub = () => {
    navigate("/", {});
  };
  return (
    <div
      style={{ backgroundImage: "url(/images/auth/auth-bg.png)" }}
      className="bg-fixed bg-top bg-cover min-w-[320px] min-h-[100vh]"
    >
      <div className="flex lg:justify-between max-lg:px-16 max-md:px-7 py-5">
        <div className="flex flex-col lg:mx-[10%] lg:w-[30%] mt-[7%] ">
          <img
            loading="lazy"
            src="/images/auth/mainLogo.png"
            className="self-center max-w-full aspect-[0.93] w-[90px]"
          />
          <div className="self-center font-bebas tracking-[2.55px] mt-2.5 text-4xl max-md:text-3xl text-green-600 ">
            FORGOT PASSWORD?
          </div>
          <div className="mt-1.5 text-base text-center text-zinc-500 max-md:max-w-full">
            No worries! Enter your email for the verification process, we will
            send a four-digit code to your email.
          </div>

          <form onSubmit={handleSubmit}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { marginTop: 1.5 },
                "& .MuiInput-underline:hover:before": {
                  borderBottom: "none", // No border bottom on hover
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px", // Adjust placeholder font size here
                },
                "& .MuiFormLabel-root": {
                  padding: "15px 3px 0px 21px", // Adjust padding as needed
                  fontSize: "17px",
                  color: "black",
                },
                "& .MuiInputBase-root": {
                  padding: "10px 0px 3px 15px", // Optional: Adjust input padding as needed
                },
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottom: "none",
                },
              }}
              noValidate
              autoComplete="off"
            >
             <TextField
        variant="standard"
        label="Create New Password"
        placeholder="***********"
        type={showPasswords.password1 ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword('password1')}
                onMouseDown={handleMouseDownPassword}
              >
                {showPasswords.password1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        className="bg-white w-full rounded-xl pt-10"
      />
      <TextField
        variant="standard"
        label="Confirm Password"
        placeholder="***********"
        type={showPasswords.password2 ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ margin: "0px 8px" }}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword('password2')}
                onMouseDown={handleMouseDownPassword}
              >
                {showPasswords.password2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        className="bg-white w-full rounded-xl pt-10"
      />
            </Box>

            <button
              onClick={() => goToIndividualSub()}
              style={{
                background:
                  "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
              }}
              className="px-4 py-3.5 mt-5 w-full text-base text-center text-white rounded-2xl max-md:px-5 max-md:max-w-full"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="max-lg:hidden flex items-end fixed right-0 bottom-0 w-[40%]">
          <Slick1 />
        </div>
      </div>
    </div>
  );
}

export default CreatePass;
