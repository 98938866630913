import { FaUser } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa6";
const MicrocredentialsData = [
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
  {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  }, {
    img: "/images/dashboard/microcredential1.png",
    name: "Robotics Course",
    info: "Lorem Ipsum is simply dummy text of the printing and type Lorem",
    logo: <FaUser />,
    enrolled: "130 enrolled",
    next: <FaGreaterThan />,
  },
];
export default MicrocredentialsData;
