import * as React from "react";
import { MdAccessTime } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
import { BsBarChart } from "react-icons/bs";
import { imageLink } from "../../auth/components/commonFunction";
import Button from "../../components/Button";
import Comment from "../../components/Comment";

function CourceDescription({ goBack, data }) {
  const backgroundImage = data.courseImage.length > 0 ? imageLink(data.courseImage[0]) : "/images/dashboard/microcredentials/robatics.png";
  return (
    <div className="px-5 py-6 mt-6 bg-white rounded-xl shadow-2xl max-md:max-w-full">
      <div className="flex gap-5 max-lg:flex-col">
        <div className="flex flex-col w-[64%] max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col grow text-[#009A39] max-md:mt-10 max-md:max-w-full">
            <div
              style={{
                backgroundImage:
                  `url(${backgroundImage})`,
              }}
              className="bg-cover relative flex items-end text-white min-h-[180px] rounded-xl"
            >
              <div className="absolute top-4 left-4 cursor-pointer">
                <button onClick={goBack} className="text-4xl border-2 px-3 rounded-lg">
                  &lt;
                </button>
              </div>
              <div className="flex items-center flex-wrap gap-x-5 gap-y-3 justify-between p-4 w-full max-md:max-w-full max-md:mt-16">
                <div className="flex gap-2 ">
                  <div className="grow my-auto text-lg max-md:text-base font-medium tracking-tight leading-none">
                    {data?.courseName}
                  </div>
                  {data?.ratingValue &&
                    <div className="flex gap-1.5 text-lg font-medium whitespace-nowrap">
                      <div className="grow">{data?.ratingValue}</div>
                      <img
                        loading="lazy"
                        src="/images/dashboard/microcredentials/star.png"
                        className="object-contain shrink-0 my-auto w-5 aspect-square"
                      />
                    </div>
                  }
                </div>
                <div className="flex flex-wrap gap-5 text-[15px] font-medium">
                  <div className="flex gap-1.5">
                    <MdAccessTime className="text-[20px] my-auto" />
                    <div>{data?.duration}</div>
                  </div>
                  <div className="flex gap-2.5">
                    <FaRegUser className="text-[19px] my-auto" />
                    <div className="basis-auto ">{data?.totalNumberOfLearners}</div>
                  </div>
                  <div className="flex gap-2.5 self-stretch whitespace-nowrap">
                    <BsBarChart className="text-[20px] my-auto" />
                    <div>{data?.level}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-start mt-7 text-3xl max-md:text-2xl font-bebas tracking-[1.96px]">
              Description
            </div>
            <div className="mt-2.5 mr-8 text-[15px] leading-6 text-[#524B6B] max-md:mr-2.5 max-md:max-w-full">
              {data?.description}
            </div>
            <div className="self-start mt-7 font-bebas text-3xl max-md:text-2xl tracking-[1.96px]">
              What will I learn?
            </div>
            <div className="text-[#524B6B] text-[15px] pl-5 mt-3  max-md:max-w-full">
              <ul className="list-disc list-outside leading-relaxed">
                {data?.whatWillILearn.map((value, idx) => (
                  <li key={idx}>{value}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-center">
            {" "}
            <a target="_blank" href="http://www.google.co.in">
              <Button text="Start Learning" />
            </a>
          </div>
        </div>
        {/* <div className="flex flex-col ml-5  max-md:ml-0 max-lg:w-full"> */}
        <div className="flex flex-col mt-1 w-[36%] max-md:mt-10 max-lg:w-full">
          <div className="flex flex-col  max-md:max-w-full">
            <div className="self-start font-bebas text-3xl text-[#009A39] tracking-[1.96px]">
              Ratings and Reviews
            </div>
            <div className=" p-3 w-full rounded-xl bg-[#F8F8F8] max-lg:max-w-full">
              <div className="flex justify-between gap-5 max-md:flex-col">
                <div className="flex flex-col w-[50%] max-md:w-full">
                  <div className="flex gap-2 my-auto text-neutral-700 max-md:mt-10">
                    <div className="text-3xl font-medium">3.4</div>
                    <div className="flex flex-col self-start mt-1 text-sm">
                      <img
                        loading="lazy"
                        src="/images/dashboard/microcredentials/stars.png"
                        className="w-32"
                      />
                      <div className="mt-1 text-[13px] max-md:mr-1.5">
                        Based on 3 reviews
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-[50%] max-md:w-full">
                  <div className="flex flex-col w-full max-md:mt-10">
                    <div className="flex gap-1 text-xs text-neutral-700">
                      <div>5 Star</div>
                      <div className="flex my-auto h-2 bg-yellow-400 rounded w-[100px]" />
                    </div>
                    <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                      <div>4 Star</div>
                      <div className="flex my-auto h-2 bg-yellow-400 rounded w-[80px]" />
                    </div>
                    <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                      <div>3 Star</div>
                      <div className="flex my-auto h-2 bg-yellow-400 rounded w-[60px]" />
                    </div>
                    <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                      <div>2 Star</div>
                      <div className="flex my-auto h-2 bg-yellow-400 rounded w-[40px]" />
                    </div>
                    <div className="flex gap-1 mt-2 text-xs text-neutral-700">
                      <div> 1 Star</div>
                      <div className="flex my-auto h-2 bg-yellow-400 rounded w-[20px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data?.comments?.map((singleComment, idx) => (
            <Comment key={idx} data={singleComment} />
          ))}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
export default CourceDescription;
