import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import { BsBellFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { activeTabAction } from "../../redux/c_plans_slice";
import LogoutModal from "../../auth/components/allModals/LogoutModal";
import { useAuth } from "../../authContext/AuthContext";
import { imageLink } from "../../auth/components/commonFunction";
import { IconFinder } from "../../auth/components/IconFinder";

const HeaderDash = ({ rating, setRating }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [showModalLogout, setShowModalLogout] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNotification, setIsOpenNotificaton] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpenNotificaton(false);
  };

  const toggleNotification = () => {
    setIsOpenNotificaton(!isOpenNotification);
    setIsOpen(false);
  };
  const [clickedNotification, setClickedNotification] = useState(null);

  const handleNotificationClick = (index) => {
    setClickedNotification(index);
  };

  return (
    <div className="flex flex-wrap gap-5 justify-between w-full border-b-[1px] pb-5 max-md:max-w-full">
      {showModalLogout && <LogoutModal onCancel={() => setShowModalLogout(!showModalLogout)} />}
      <div className="flex gap-5 max-md:gap-3 ">
        <div className="my-auto uppercase text-4xl max-md:text-3xl tracking-[1px] font-bebas">
          <span className=" text-neutral-700">Welcome</span>{" "}
          <span className="text-amber-500 ">{user?.name}</span>
        </div>
        <img
          alt="image"
          loading="lazy"
          src="/hello.png"
          className="object-contain  my-auto aspect-square w-[40px] max-md:w-[35px]"
        />
      </div>
      <div className="flex flex-wrap gap-10 max-md:gap-5 max-md:mr-3">
        <div className="flex flex-auto gap-2  px-6 py-3 max-md:py-2 text-sm leading-relaxed text-black bg-white border-[#E8E8E8] border-[1px] rounded-xl max-md:px-3">
          <IoIosSearch className="my-auto text-2xl text-gray-400 " />
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="w-[16vw] max-md:w-full outline-none border-none my-auto"
            placeholder="Search for industries, courses..."
          />
        </div>
        <div className="flex flex-auto gap-4">
          <div className="flex justify-center items-center border-[1px] px-3 max-md:p-2 rounded-lg">
            <span
              className="star max-md:text-xl"
              style={{
                cursor: "pointer",
                color: rating >= 1 ? "gold" : "gray",
                fontSize: "35px",
              }}
              onClick={() => {
                setRating(rating === 1 ? 0 : 1);
              }}
            >
              ★
            </span>
          </div>
          <img
            onClick={toggleNotification}
            loading="lazy"
            src="/images/dashboard/notification.png"
            className=" w-14 h-14 max-md:w-12 max-md:h-12 rounded-lg border-[1px] p-3 max-md:p-2"
          />
          <div className="relative">
            <div onClick={toggleDropdown} className="flex items-center gap-3 p-1 border border-gray-200 rounded-lg ">
              <img
                src={user?.profilePhoto ? imageLink(user?.profilePhoto) : "/user.png"}
                alt="Profile"
                className="w-12 h-12 rounded-lg object-cover max-md:w-10 max-md:h-10 border shadow-md"
              />
              <div className="flex-grow flex justify-end">
                <button
                  className={`p-1 transition-transform duration-200 ease-in-out ${isOpen && 'rotate-180'}`}
                >
                  <IconFinder icon={isOpen ? "up" : "down"} className=" transition-transform duration-300" />
                </button>
              </div>
            </div>
            {/* dropdown */}
            {isOpen && (
              <div
                style={{ zIndex: "999" }}
                className="absolute right-0 py-4 mt-3 w-56 bg-white border border-gray-200 rounded-xl shadow-lg"
              >
                <ul>
                  <li
                    onClick={() => dispatch(activeTabAction(14))}
                    className="flex gap-2 px-3 py-1 hover:bg-gray-100 cursor-pointer"
                  >
                    <img
                      loading="lazy"
                      src="/my-profile.png"
                      className=" w-6 h-6 my-auto"
                    />
                    Your Profile
                  </li>
                  <li
                    onClick={() => dispatch(activeTabAction(15))}
                    className=" flex gap-2 px-3 py-1 mt-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <img
                      loading="lazy"
                      src="/setting.png"
                      className=" w-6 h-6 my-auto"
                    />
                    Settings
                  </li>
                  <li
                    onClick={() => setShowModalLogout(true) || setIsOpen(false)}
                    className="flex gap-2 px-3 py-1 mt-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <img
                      loading="lazy"
                      src="/logout.png"
                      className=" w-6 h-6 my-auto"
                    />
                    Logout
                  </li>
                </ul>
              </div>
            )}
            {/* notification */}
            {isOpenNotification && (
              <div
                style={{ zIndex: "999", scrollbarWidth: "none" }}
                className="absolute right-32 max-md:right-0 mt-3 pb-5 w-[330px] max-md:w-[200px] overflow-y-scroll h-[450px] bg-white border  border-gray-200 rounded-3xl shadow-2xl "
              >
                <div className="flex justify-between">
                  <h3 className="text-[22px] uppercase font-bebas tracking-[1px] px-4 mt-5 pb-2 ">
                    Notifications
                  </h3>
                  <h1
                    onClick={() => setIsOpenNotificaton(false)}
                    className="my-auto pr-4 pt-1 font-semibold text-lg cursor-pointer"
                  >
                    &#10005;
                  </h1>
                </div>

                {notifications.map((notification, index) => {
                  const isClicked = clickedNotification === index;
                  return (
                    <div
                      key={index}
                      className={`flex z-10 flex-col px-7 py-3 w-full border-t-[1px] border-[#D5D5D5] cursor-pointer${isClicked ? "bg-gray-200" : "bg-white"
                        }`}
                      onClick={() => handleNotificationClick(index)}
                    >
                      <div className="flex gap-2.5 self-start cursor-pointer">
                        <BsBellFill
                          className={`text-xl ${isClicked ? "bg-[#1D68FF]" : "bg-[#9F9F9F]"
                            } text-white h-10 w-10 p-2 rounded-full`}
                        />
                        <div className="flex flex-col my-auto">
                          <div className="text-xs text-black">
                            {notification.title}
                          </div>
                          <div className="self-start text-xs text-neutral-400">
                            {notification.time}
                          </div>
                        </div>
                      </div>
                      <div className="mt-1.5 text-xs font-light text-black cursor-pointer">
                        {notification.description}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDash;
const notifications = [
  {
    id: 1,
    title: "New Course Added",
    description:
      "We have added the new course in the microcredentials - Robotics. Check it out!",
    time: "2 hr ago",
  },
  {
    id: 2,
    title: "Jane Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
    time: "2 hr ago",
  },
  {
    id: 3,
    title: "Jane Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
    time: "2 hr ago",
  },
  {
    id: 4,
    title: "Jane Doe",
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
    time: "2 hr ago",
  },
];
