import React from 'react'

const Comment = ({ data }) => {
  return (
    <div className="flex flex-col mt-6 max-w-full">
      <div className="flex flex-wrap">
        <div className="flex flex-col flex-1">
          <div className="text-base font-medium text-gray-600">
            Joseph Stanly
          </div>
          <div className="self-start text-sm text-neutral-400">
            Jan 2021
          </div>
        </div>
        <img
          loading="lazy"
          src="/images/dashboard/microcredentials/stars.png"
          className="w-28 h-6"
        />
      </div>
      <p className="mt-2 text-[15px] text-gray-600 max-md:mr-2.5 max-md:max-w-full">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non id
        neque sit aliquam nam molestie dignissim ac eget. Fames congue
        faucibus in fermentum proin. A accumsan, convallis elementum amet
        sed.
      </p>
    </div>
  )
}

export default Comment