import { LoaderAction } from "../../redux/c_plans_slice";
import { DeleteAction, PopupAction } from "../../redux/common";
import { store } from "../../redux/store";

export const HandleModal = (enable, type = "new", address = "", initialValue = "") => {
    store.dispatch(PopupAction({ enable, type, address, initialValue }))
}

export const ClearPopupMemory = () => {
    store.dispatch(PopupAction({ enable: false, address: '', type: 'new', initialValue: '' }));
}

export const DeleteConformations = (address, id, heading = "", content = '', buttonText = '') => {
    store.dispatch(DeleteAction({
        enable: true, answer: false, address, id, heading,
        content,
        buttonText
    }))
}

export const ClearDeleteMemory = () => {
    store.dispatch(DeleteAction({
        enable: false,
        address: '',
        answer: false,
        id: '', heading: '',
        content: "",
        buttonText: ""
    }));
}

export const imageLink = (imageName) => {
    let url = "https://dev-futurecapacity-api.flynautstaging.com/uploads/"
    return url + imageName;
}

export const Loading = (value, message = '') => { store.dispatch(LoaderAction({ enable: value, message })); }

export const CallingAxios = async (myFunction, message = '') => {
    Loading(true, message);
    let result = await myFunction;
    Loading(false, '');
    return result;
}

export const TrimJsonValues = (data) => {
    const trimmedData = {};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            if (typeof value === 'string') {
                trimmedData[key] = value.trim();
            } else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                trimmedData[key] = TrimJsonValues(value); // Recursively trim nested objects
            } else {
                trimmedData[key] = value;
            }
        }
    }
    return trimmedData;
}

export function truncateText(text, wordLimit) {
    const words = text.split(' ');

    if (words.length <= wordLimit) {
        return text; // Return the original text if it meets the limit
    }

    return words.slice(0, wordLimit).join(' ') + '...'; // Join the first `wordLimit` words and add ellipsis
}