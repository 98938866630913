import axios from "axios";
import Cookies from "js-cookie";

const ApiUrl = "https://dev-futurecapacity-api.flynautstaging.com/";
// const ApiUrl = "http://localhost:11015"

export const Axios = axios.create({
  baseURL: ApiUrl,
});

Axios.interceptors.request.use(
  function async(config) {
    // Get the Bearer token from cookies
    const token = Cookies.get("Token");
    console.log("token", token);

    // Set the Authorization header if the token exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.url?.includes("/create-post")) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 403) {
        if (error.response.data && error.response.data.type === 2) {
          // store.dispatch(setAlreadySigned(true));
        }
      } else if (
        error.response.status === 403 ||
        error.response.status === 401
      ) {
        // store.dispatch(setUnAuthorized(true));
      }
    }

    return Promise.reject(error);
  }
);
