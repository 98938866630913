import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext/AuthContext";
import AllModals from "../../AllModals";

function AuthenticLayout({ children }) {
  const { logged, user, login, logout } = useAuth();
  const navigate = useNavigate();
  const [proceed, setProceed] = useState(false);
  const tkn = Cookies.get("Token");
  const atype = Cookies.get("Atype");

  useEffect(() => {
    if (!tkn) {
      navigate("/login");
      return;
    }

    // BackgroundAuthApi().then((response) => {
    //     const userData = response.data;
    //     login(userData, tkn);
    // }).catch((error) => {
    //     logout();
    //     console.error('Error during authentication:', error.response?.data?.message);
    // });

    // console.log("come", logged);
    if (logged) {
      if (atype == 2) {
        navigate("/school-portal");
      } else if (user.emailVerified) {
        setProceed(true);
        // navigate("/otp");
      } else {
        navigate("/");
      }
    }
  }, [logged]);

  return (
    <>
      {proceed && logged && (
        <AllModals>
          <div className="flex flex-col h-[100%] w-[100%]">{children}</div>
        </AllModals>
      )}
    </>
  );
}
export default AuthenticLayout;
