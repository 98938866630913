import * as React from "react";
import HeaderDash from "../HeaderDash";
import { FaArrowRight } from "react-icons/fa6";

function MilitaryOption({setActiveTab}) {
  return (
    <div className="p-4 mb-10 min-w-[320px]">
      <HeaderDash setActiveTab={setActiveTab}/>
      <div className="text-3xl pl-4 mt-12 font-bebas tracking-widest text-green-600 max-md:text-2xl ">
        miliary OPTIONS
      </div>
      <div className="grid grid-cols-3 gap-4 px-4 mt-5 max-md:grid-cols-2 max-sm:grid-cols-1">

      {militaryOp.map((item)=>{
        return(
          <div style={{boxShadow:" 0px 4px 44px 0px rgba(0, 0, 0, 0.06)"}} className="flex bg-white p-3 gap-2 rounded-lg max-md:flex-col">
            <div className="flex flex-col w-[30%] ">
              <img
                loading="lazy"
                src={item.img}
                className="object-contain shrink-0 max-w-full  aspect-square w-[150px] "
              />
            </div>
            <div className="flex flex-col w-[70%]  max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-neutral-700">
                <div className="text-base font-semibold leading-snug">
                  {item.name}
                </div>
                <div className="mt-1  text-sm leading-65">
                 {item.desc}
                </div>
              </div>
            </div>
        </div>
        )
      })}
      </div>

      <div className="text-3xl pl-4 mt-12 font-bebas tracking-[1px] text-green-600 max-md:text-2xl ">
      ASVAB TEST
      </div>
      <div className="grid grid-cols-3 gap-4 px-4 mt-5 max-md:grid-cols-2 max-sm:grid-cols-1">

      {ASVAB.map((item)=>{
        return(
          <div style={{boxShadow:" 0px 4px 44px 0px rgba(0, 0, 0, 0.06)"}} className="flex bg-white p-3 gap-2 rounded-lg max-md:flex-col">
            <div className="flex flex-col w-[30%] ">
              <img
                loading="lazy"
                src={item.img}
                className="object-contain shrink-0 max-w-full  aspect-square w-[150px] "
              />
            </div>
            <div className="flex flex-col w-[70%]  max-md:w-full">
              <div className="flex flex-col self-stretch my-auto text-neutral-700">
                <div className="text-base font-semibold leading-snug">
                  {item.name}
                </div>
                <div className="mt-1  text-sm leading-65">
                 {item.desc}
                </div>
              </div>
            </div>
        </div>
        )
      })}
      </div>
      
    <div className="flex mt-10 mx-4 justify-center items-center">
      <button  className=" flex gap-3 bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]  text-white px-5 py-3 rounded-lg hover:bg-blue-600"
      >
        The 5 Steps to Enlisting in the Military
         <FaArrowRight className="my-auto text-xl" />
      </button>
    
    </div>
  
     
    </div>
  );
}

export default MilitaryOption;

const militaryOp = [
  {
    img: "/images/dashboard/career-planning/army.png",
    name: "United States Army",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },
  {
    img: "/images/dashboard/career-planning/guard.png",
    name: "United States Coast Guard",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },
  {
    img: "/images/dashboard/career-planning/navy.png",
    name: "United States Navy",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },
  {
    img: "/images/dashboard/career-planning/air-force.png",
    name: "United States Air Force",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },
  {
    img: "/images/dashboard/career-planning/marine.png",
    name: "United States Marine Corps",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },
  {
    img: "/images/dashboard/career-planning/space-force.png",
    name: "United States Space Force",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },
 
];
const ASVAB = [
  {
    img: "/images/dashboard/career-planning/army.png",
    name: "ASVAB Test",
    desc: "Lorem ipsum dolor sit amet consectetur. Turpis tincidunt faucibus morbi a.",
  },]