import React, { useState } from "react";
import { FaArrowLeft, FaSearch, FaCheck } from "react-icons/fa";
import {INTERESTDATA} from './interestData'

const AddInterest = ({ setInterest, onSave  }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // Filter items based on the search query
  const filteredItems = INTERESTDATA.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleItemClick = (id) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(itemId => itemId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSave = () => {
    if (onSave) {
      onSave(selectedItems);
    }
    setInterest(false); // Close the component after saving
  };
  return (
    <div className="flex flex-col px-3 py-5 max-md:px-2 w-full rounded-3xl bg-zinc-50 max-md:max-w-full">
      <div className="flex flex-col p-6 max-md:p-3 items-center w-full bg-white rounded-xl shadow-2xl max-md:max-w-full">
        <div className="flex gap-3 justify-between items-start self-start max-w-full">
          <div onClick={()=>setInterest(false)}
            style={{ boxShadow: " 0px 20px 50px 0px rgba(0, 0, 0, 0.12)" }}
            className="bg-white rounded-full p-3 max-md:p-2 cursor-pointer"
          >
            <FaArrowLeft className="w-5 h-5 text-[#1D68FF] max-md:w-4 max-md:h-4" />
          </div>
          <div className="flex flex-col">
            <div className="self-start text-3xl font-bebas tracking-[1px] text-neutral-700 max-md:text-2xl">
              Add Interest
            </div>
            <div className="mt-1 text-xs text-neutral-400">
              You can select multiple interests.
            </div>
          </div>
        </div>
        <div className="md:w-[80%] max-md:w-full mt-10 max-md:mt-5 mx-auto">
          <div className="p-4">
            <div
              style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.05)" }}
              className="relative mb-4"
            >
              <input
                type="text"
                placeholder="Search interests"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border px-4 py-3 rounded-xl w-full border-none outline-none"
              />
              <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            </div>

            <div className="grid grid-cols-6 gap-4 mt-10 max-sm:gap-2 max-lg:grid-cols-4 max-md:grid-cols-4 max-sm:grid-cols-2 max-md:mt-5">
              {filteredItems.length === 0 ? (
                <li>No items found.</li>
              ) : (
                filteredItems.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      background: item.bg,
                      position: "relative",
                    }}
                    className={`flex flex-col justify-center py-2 items-center w-full rounded-xl cursor-pointer ${
                      selectedItems.includes(item.id)
                        ? "ring-2 ring-[#1D68FF]"
                        : ""
                    }`}
                    onClick={() => handleItemClick(item.id)}
                  >
                    {selectedItems.includes(item.id) && (
                      <FaCheck className="absolute -top-2 -right-1 text-[#1D68FF] bg-white w-5 h-5 p-[3px] rounded-full border-[1px]" />
                    )}
                    <img
                      src={item.image}
                      alt={item.name}
                      className=" max-w-12 max-md:max-w-10"
                    />
                    <div className="text-[#fff] mt-1 text-xs">{item.name}</div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <button
          onClick={handleSave}
          className="p-4 mt-10 mb-5 max-w-full w-[70%] text-[15px] text-center text-white whitespace-nowrap rounded-lg bg-[linear-gradient(90deg,#1D68FF_50%,#9A52DE_97.52%)]"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddInterest;


