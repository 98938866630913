import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    LoaderMemory: {
        enable: false,
    },
    userMemory: {},
    PopupMemory: {
        enable: true,
        address: '',
        type: 'new',
        initialValue: ''
    },
    DeleteMemory: {
        enable: false,
        address: '',
        answer: false,
        id: '',
        heading: "",
        content: "",
        buttonText: ""
    },

}
export const common_slice = createSlice({
    name: "cPlanning",
    initialState,
    reducers: {
        PopupAction(state, action) { state.PopupMemory = action.payload },
        userAction(state, action) { state.userMemory = action.payload },
        LoaderAction(state, action) { state.LoaderMemory = action.payload },
        DeleteAction(state, action) { state.DeleteMemory = action.payload },
    }
})
export const { DeleteAction, PopupAction, LoaderAction, userAction } = common_slice.actions;
export default common_slice.reducer;